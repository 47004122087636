import { jobClassification as getJobClassification } from '@tymbe/job-classifications/job-clasification';
import { getConst } from '@tymbe/legislatives/Constants';
import { CzechConstantType } from '@tymbe/legislatives/czechia/CzechConstantType';
import { CompanyData } from '@tymbe/schema/company.interface';
import { Form, FormApi, FormState } from 'informed';
import { useRef, useState } from 'react';
import useCollapse from 'react-collapsed';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DeepPartial } from 'utility-types';

import AffectedList from './components/AffectedList';
import feathersClient from '../../../../apiClient';
import { useUser } from '../../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { resolveUploadableFiles } from '../../../../components/blob/blob.utils';
import { SubmitButton } from '../../../../components/buttons';
import { getValuesForPatch } from '../../../../components/forms/form-util';
import TyCheckbox from '../../../../components/inputs/TyCheckbox';
import Card from '../../../../components/Layout/Card';
import ModalShell from '../../../../components/modals/ModalShell';
import Spinner from '../../../../components/Spinner';
import { osszCodeOptions } from '../../../../components/TySelectOsszCode/TySelectOsszCode';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { RequestBody } from '../../../../types/ReactQuery';
import { Roles } from '../../../../utils/enums';
import { mapCompanyData } from '../../company.utils';
import CompanyForm, {
  CompanyFormValues,
  companyTypeOptions,
  countryOptions,
  stateOptions,
} from '../../components/CompanyForm';
import { transformShiftLabels } from '../shift-template/utils/ShiftTemplate.utils';

interface CompanyPatchParams {
  query: {
    $updatePayLimitsInFutureShifts: boolean;
    $updatePayLimitsInShiftTemplates: boolean;
  };
}

interface LimitModalProps {
  shift_template_checkbox: boolean;
  shift_checkbox: boolean;
}

const EditCompany = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const formApiRef = useRef<FormApi<CompanyFormValues>>();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false });

  const canChangeDefaultSettings = useUser().hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);
  const { companyId } = useParams();

  const history = useNavigate();

  const { mutateAsync: patchCompany } = useMutation(
    ['PatchCompany'],
    (req: RequestBody<DeepPartial<CompanyData> | FormData>): Promise<CompanyData> =>
      feathersClient.service('company').patch(req.id, req.body as Partial<CompanyData>, req.params),
  );

  const { data: company, isLoading } = useQuery(
    ['FetchCompanyDetail', companyId],
    async (): Promise<CompanyData> =>
      feathersClient.service('company').get(companyId!, {
        query: {
          $eager: '[address, contactPerson, contractor, defaultSettings.[position, defaultPerk, defaultUtility, defaultDocumentType]]',
        },
      }),

    { enabled: !!companyId },
  );

  if (!companyId) {
    return <Navigate to="/company" />;
  }

  const onSubmit = async (formState?: FormState<CompanyFormValues>, companyPatchParams?: CompanyPatchParams) => {
    if (!companyId) {
      if (!company?.address_id) ErrorAlert('Nebylo možné dohledat identifikátor adresy');
      history('/company');
      return;
    }
    if (!formState) return;
    setFormDisabled(true);
    const modifiedAndNulled = getValuesForPatch(formState);
    const { values, modified } = formState;
    const companyData = mapCompanyData({
      ...modifiedAndNulled as CompanyFormValues,
      address_id: company?.address_id || undefined,
      company_id: companyId,
      settings_id: company?.defaultSettings?.id,
    });

    if (modified.pay_supplement && values.pay_supplement) {
      companyData.defaultSettings = {
        ...companyData.defaultSettings,
        pay_supplement: values.pay_supplement,
      };
    }

    companyData.defaultSettings = {
      ...companyData.defaultSettings,
      max_payment_base: Number(values.max_payment_base),
      min_payment_base: Number(values.min_payment_base),
      max_credits: Number(values.max_credits),
      min_credits: Number(values.min_credits),
    };

    if (!canChangeDefaultSettings) {
      delete companyData.defaultSettings;
    }

    try {
      await patchCompany({
        id: companyId,
        body: companyData,
        params: companyPatchParams,
      });
      const formData = resolveUploadableFiles(modifiedAndNulled);
      if (formData) {
        await patchCompany(
          { id: companyId, body: formData, params: { headers: { 'Content-Type': 'multipart/form-data' } } },
        );
      }
      SuccessAlert('Firma byla úspěšně upravena');
      history('/company');
    } catch (err) {
      if ((err as Error).message === 'Can\'t change fulfillment to agency when some shift-template contains forbidden ISCO') {
        ErrorAlert(
          'Nelze změnit režim spolupráce na Agenturu, když existuje pracovní pozice s nepovolenou klasifikací ISCO',
        );
      } else if ((err as Error).message === 'Can\'t change fulfillment to agency when some future shift contains forbidden ISCO') {
        ErrorAlert(
          'Nelze změnit režim spolupráce na Agenturu, když existuje směna v budoucnosti s nepovolenou klasifikací ISCO',
        );
      } else {
        ErrorAlert('Při editaci adresy firmy došlo k chybě');
      }
    }
    setFormDisabled(false);
  };

  const mapInitialData = () => {
    if (!company) return undefined;
    const iscoId = company.defaultSettings?.job_classification_ids?.cz_isco;
    const naceId = company.defaultSettings?.job_classification_ids?.cz_nace;
    const minimumWage = getConst(
      CzechConstantType.MINIMUM_WAGE,
      new Date().toISOString(),
      'europe/prague',
    );
    return {
      ...company,
      contractor: company?.contractor?.length && company?.contractor?.length > 0 ? company?.contractor : undefined,
      job_classification_isco: iscoId ? {
        id: iscoId,
        name: getJobClassification({ id: iscoId, type: 'cz_isco' }),
      } : undefined,
      job_classification_nace: naceId ? {
        id: naceId,
        name: getJobClassification({ id: naceId, type: 'cz_nace' }),
      } : undefined,
      labels: company.defaultSettings?.labels && transformShiftLabels(company.defaultSettings?.labels),

      stateOption: stateOptions.find((x) => x.value === company.state),
      countryOption: countryOptions.find((x) => x.value === company.address?.country) || countryOptions[0],
      typeOption: companyTypeOptions.find((x) => x.value === company.fulfillment_type),
      pay_supplement: company.defaultSettings?.pay_supplement,

      defaultRequirements: [...company.defaultSettings?.defaultPerk || [],
        ...company.defaultSettings?.defaultDocumentType || [],
      ],

      osszCodeOption: osszCodeOptions.find((x) => x.value === company.ossz_code),
      defaultPerk: company.defaultSettings?.defaultPerk,
      defaultUtility: company.defaultSettings?.defaultUtility,
      position: company.defaultSettings?.position,
      max_payment_base: company.defaultSettings?.max_payment_base || (Math.round((minimumWage / 168) * 10) / 10) * 2,
      min_payment_base: company.defaultSettings?.min_payment_base || Math.round((minimumWage / 168) * 10) / 10,
      max_credits: company.defaultSettings?.max_credits || 50,
      min_credits: company.defaultSettings?.min_credits || 0,
    };
  };

  const onSubmitCompanyEdit = async (formState: FormState<CompanyFormValues>) => {
    const modified = getValuesForPatch(formState);

    if (
      company?.defaultSettings && (
        (modified.min_credits && modified.min_credits > company?.defaultSettings?.min_credits)
      || (modified.max_credits && modified.max_credits < company?.defaultSettings?.max_credits)
      || (modified.min_payment_base && modified.min_payment_base > company?.defaultSettings?.min_payment_base)
      || (modified.max_payment_base && modified.max_payment_base < company?.defaultSettings?.max_payment_base)
      )
    ) {
      setShowModal(true);
    } else {
      onSubmit(formState);
    }
  };

  const onSubmitModal = async (formState: FormState<LimitModalProps>) => {
    const { values } = formState;

    const companyPatchParams = {
      query:
      {
        $updatePayLimitsInFutureShifts: false,
        $updatePayLimitsInShiftTemplates: false,
      },
    };

    if (values.shift_template_checkbox) {
      companyPatchParams.query.$updatePayLimitsInShiftTemplates = true;
    }

    if (values.shift_checkbox) {
      companyPatchParams.query.$updatePayLimitsInFutureShifts = true;
    }

    onSubmit(formApiRef.current?.getFormState(), companyPatchParams);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Editace firmy </h1>
        <Card>
          {isLoading ? <Spinner />
            : (
              <>
                <Form<CompanyFormValues> name="company-form" onSubmit={onSubmitCompanyEdit} initialValues={mapInitialData()} formApiRef={formApiRef}>
                  <CompanyForm company={company} />
                  <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
                </Form>
                <ModalShell
                  onClose={() => setShowModal(false)}
                  showModal={showModal}
                  className="overflow-auto max-h-96"
                >
                  <div className="flex flex-row justify-between items-center">
                    <h2>
                      Změna limitu hodinové odměny a kreditu
                    </h2>
                  </div>
                  <>
                    <div className="flex flex-row justify-between items-center mb-5">
                      Zmenšují se rozsahy limitů, chcete vynutit změnu?
                    </div>

                    <Form onSubmit={onSubmitModal}>
                      <div className="p-1">
                        <TyCheckbox
                          name="shift_template_checkbox"
                          label="u všech pracovních pozic"
                          className="p-1"
                        />
                        <TyCheckbox
                          name="shift_checkbox"
                          label="u všech směn v budoucnosti"
                          className="p-1"
                        />
                      </div>
                      <div className="flex gap-x-1 justify-end">
                        <button
                          className="ty-button-secondary"
                          type="button"
                          {...getToggleProps()}
                        >
                          <span>
                            {isExpanded ? 'Skrýt ovlivněné' : 'Zobrazit ovlivněné'}
                          </span>
                        </button>
                        <SubmitButton
                          buttontext="Uložit"
                          className="ty-button-primary"
                        />
                        <button
                          className="ty-button-secondary"
                          onClick={() => setShowModal(false)}
                          type="button"
                        >
                          Zrušit
                        </button>
                      </div>
                    </Form>
                  </>
                  <div {...getCollapseProps()}>
                    <AffectedList companyId={companyId} values={formApiRef.current?.getFormState()} />
                  </div>
                </ModalShell>
              </>
            )}
        </Card>
      </Wrapper>
    </Container>
  );
};

export default EditCompany;
