import { AbsenceState } from '@tymbe/schema/enums';
import { useQuery } from 'react-query';

import feathersClient from '../../../apiClient';
import { resolvedAbsenceHeaders } from './components/headers/resolvedAbsenceHeaders';
import { AbsenceRow } from './components/AbsenceRow';
import {
  TyTable,
  TyTableRow,
  TyTablePagination,
  TyTableSortHeader,
  NoDataTableRow,
  TableRowSpinner,
} from '@tymbe/ty-components/table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import { Form } from 'informed';
import useURLParamsHandler from '../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { getPaginatedResponse } from '../../../apiClient/utils';

export const resolvedAbsencesQueryId = 'admin/resolvedAbsences';

export const ResolvedAbsencesTab = () => {
  const [{ allSearchParams, urlParams }] = useURLParamsHandler();
  const sort = allSearchParams.sort ?? { reviewed_at: -1 };
  const search = allSearchParams.search;
  const currentPage = allSearchParams.page ?? 1;
  const pageSize = allSearchParams.pageSize ?? defaultPageSizeOptions[0].value;
  const excuseId = urlParams.get('excuseId');

  const { data: excuses, isLoading } = useQuery(
    [
      resolvedAbsencesQueryId,
      pageSize,
      currentPage,
      sort,
      allSearchParams.search,
      excuseId,
    ],
    () =>
      feathersClient
        .service('application-excuse')
        .find({
          query: {
            $skip: pageSize * (currentPage - 1),
            $limit: pageSize,
            $sort: sort,
            'application_excuse.state[$ne]': AbsenceState.PROCESSING,
            $eager:
              '[files, application(withDeleted).[shift.company, person.[accountState, personData]]]',
            $joinRelation: 'application(withDeleted).[person, shift]',
            $modify: {
              quickFilter: search?.split(' '),
            },
            ...(excuseId && { 'application_excuse.id': excuseId }),
          },
        })
        .then(getPaginatedResponse)
  );

  return (
    <Form>
      <TyTable>
        <tbody>
          <TyTableRow>
            {resolvedAbsenceHeaders.map((col, idx) => (
              <TyTableSortHeader
                key={idx.toString()}
                field={col.sortable ? col.value : undefined}
                className="border-x-0"
              >
                {col.label}
              </TyTableSortHeader>
            ))}
          </TyTableRow>
          {excuses?.data.map((excuse) => (
            <AbsenceRow excuse={excuse} key={excuse.id} />
          ))}
          <NoDataTableRow
            visible={excuses?.data.length === 0}
            colSpan={resolvedAbsenceHeaders.length}
          />
          <TableRowSpinner
            colSpan={resolvedAbsenceHeaders.length}
            visible={isLoading}
          />
        </tbody>
      </TyTable>
      <TyTablePagination rowsCount={excuses?.total} />
    </Form>
  );
};
