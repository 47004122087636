// disable for creating a clickable div for row selecting
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Paginated, Query } from '@feathersjs/feathers';
import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import PaymentRow from './PaymentRow';
import feathersClient from '../../../apiClient';
import { SecondaryButton } from '../../../components/buttons';
import Card from '../../../components/Layout/Card';
import { TablePagination, useTablePagination } from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import { PaymentAuthorizedData } from '../../../types/TymbeApi';

interface PaymentListProps {
  personId: string;
  companyId: number;
  from: Moment;
  to: Moment;
  salaryType?: CzechSalaryType;
}

interface RowsMap {
  [key: number]: number
}

const PaymentList = ({ personId, companyId, from, to, salaryType }: PaymentListProps) => {
  const { setPageParams, pageStart: paginationStart, pageSize: paginationPageSize } = useTablePagination();
  const [selectedRows, setSelectedRows] = useState<RowsMap>({});
  const [selectedSum, setSelectedSum] = useState<number>(0);
  const [checkedState, setCheckedState] = useState(false);

  const query: Query = {
    'payment_authorized.person_id': personId,
    'payment_authorized.company_id': companyId,
    'payment_authorized.date_time': {
      $gte: from.toISOString(),
      $lte: to.toISOString(),
    },
    $eager: '[paymentRequest.paymentTransaction, attendance(withDeleted).application.shift]',
    $sort: { created_at: -1 },
    $modify: 'withDeleted',
    $skip: paginationStart,
    $limit: paginationPageSize,
  };
  if (salaryType) {
    query.salary_type = salaryType;
  }
  const { data: payments } = useQuery<Paginated<PaymentAuthorizedData>>(
    ['payment-authorized', query],
    () => feathersClient.service('payment-authorized').find({ query }),
  );

  const addSelectedRows = (obj: object) => {
    const paymentSum = Object.values(obj).reduce((a: number, b: number) => a + b, 0);
    setSelectedSum(paymentSum);
  };

  const onRowClick = (payment: PaymentAuthorizedData) => {
    const newSelRows = { ...selectedRows };
    if (payment.id in newSelRows) {
      delete newSelRows[payment.id];
    } else {
      newSelRows[payment.id] = Number(payment.value);
    }
    setSelectedRows(newSelRows);
  };

  const selectAllRows = () => {
    if (!payments) return;
    if (payments?.data.length === Object.keys(selectedRows).length) {
      setSelectedRows({});
      return;
    }
    const allRows = payments?.data.reduce((o, pmnt) => ({ ...o, [pmnt.id]: Number(pmnt.value) }), {});
    setSelectedRows(allRows);
    setCheckedState(true);
  };

  useEffect(() => {
    addSelectedRows(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRows({});
  }, [paginationStart, paginationPageSize]);

  useEffect(() => {
    if (payments?.data.length !== Object.keys(selectedRows).length) {
      setCheckedState(false);
    }
  }, [payments?.data.length, selectedRows]);

  return (
    <Card>
      <SecondaryButton
        type="button"
        onClick={selectAllRows}
        className={checkedState ? 'mb-3 border-error text-error focus:border-error focus:text-error' : 'mb-3 border-primary focus:border-primary focus:text-secondary'}
      >
        {checkedState ? 'Zrušit výběr' : 'Vybrat všechny'}
      </SecondaryButton>
      <div>
        {payments?.data.map((payment: PaymentAuthorizedData) => (
          // disable for creating a clickable div for row selecting
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onClick={() => onRowClick(payment)}
            key={payment.id}
            className={
              payment.id in selectedRows ? 'mb-3 border-primary-400 border-2 rounded-lg' : 'border-secondary-200 border rounded-lg mb-3'
            }
          >
            <PaymentRow payment={payment} />
          </div>
        ))}
      </div>
      {Object.keys(selectedRows).length > 0
        ? <div>Součet vybraných částek je {selectedSum} Kč</div> : null}
      <TablePagination
        {...setPageParams()}
        rowsCount={payments?.total}
      />
    </Card>
  );
};

export default PaymentList;
