import { Paginated, Query } from '@feathersjs/feathers';
import { PaymentRequestData } from '@tymbe/schema/payment-request.interface';
import { OrderByDirection } from 'objection';
import { useEffect, useState, useCallback } from 'react';
import { UseMutateAsyncFunction, useMutation, useQuery } from 'react-query';

import feathersClient from '../../../apiClient';
import Container from '../../../containers';
import AdminAdministrationPaymentConfirmationContainer from '../../../containers/admin/administration/paymentConfirmation';
import useURLParamsHandler from '../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { getPaginatedResponse } from '../../../apiClient/utils';

interface CustomSortFilters {
  sortByExportedAt?: OrderByDirection;
  sortByCompanyName?: OrderByDirection;
  sortByPersonProperty?: {
    property: string;
    sortOrder: OrderByDirection;
  };
}

const AdminAdministrationPaymentConfirmationPage = () => {
  const pageTitle = 'Výplaty';
  const commonQuery = {
    $eager:
      '[creditTransaction.[person.personData], paymentTransaction, paymentAuthorized.[person.personData, company]]',
  };
  const [{ allSearchParams }] = useURLParamsHandler();

  const [onlyUnresolved, setOnlyUnresolved] = useState(
    allSearchParams.onlyUnresolved === undefined ? true : allSearchParams.onlyUnresolved,
  );
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState<Record<string, number>>();

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [customSortFilter, setCustomSortFilter] = useState<CustomSortFilters>(
    {},
  );

  const [dateRange, setDateRange] = useState<Date[] | null>(null);
  const [payoutAmount, setPayoutAmount] = useState<number | undefined>();
  const [sortField, setSortField] = useState('');

  const getParams = useCallback(() => {
    let params: Query = {
      $modify: {},
    };

    if (payoutAmount) {
      params = { $modify: {} };
      params.$modify.payoutMaxAmount = [payoutAmount];
      params.$limit = 5000;
      return params;
    }

    params.$skip = currentPage;
    params.$limit = pageSize;

    if (customSortFilter.sortByExportedAt) {
      params.$modify.sortByExportedAt = [customSortFilter.sortByExportedAt];
    }

    if (customSortFilter.sortByCompanyName) {
      params.$modify.sortByCompanyName = [customSortFilter.sortByCompanyName];
    }

    if (customSortFilter.sortByPersonProperty) {
      params.$modify.sortByPersonProperty = [
        customSortFilter.sortByPersonProperty.property,
        customSortFilter.sortByPersonProperty.sortOrder,
      ];
    }

    if (dateRange) {
      params['payment_request.created_at'] = {
        $gte: dateRange[0].toISOString(),
        $lte: dateRange[1].toISOString(),
      };
    }

    if (searchString) {
      params.$modify.searchString = [searchString];
    }

    if (onlyUnresolved) {
      params.$modify.unpaid = true;
    }

    return params;
  }, [
    currentPage,
    dateRange,
    onlyUnresolved,
    pageSize,
    payoutAmount,
    customSortFilter,
    searchString,
  ]);

  const onSubmitPayoutAmount = (inputAmount: string) => {
    setPayoutAmount(Number(inputAmount?.replace(/\s/g, '')));
  };

  const onSearch = (value: string) => {
    setCurrentPage(0);
    setSearchString(value);
  };

  const { data: paymentRequestData, isLoading } = useQuery(
    ['admin/loadPaymentRequest', getParams(), sort],
    async () =>
      feathersClient.service('payment-request').find({
        query: {
          $sort: sort ?? { created_at: 1 },
          ...commonQuery,
          ...getParams(),
        },
      }),
  );

  const onChangePage = (pageStart: number, pageEnd: number) => {
    setCurrentPage(pageStart);
    setPageSize(pageEnd - pageStart);
  };

  const { mutateAsync: fetchCSVData } = useMutation(
    ['CSVExport'],
    async () =>
      getPaginatedResponse(
        await feathersClient.service('payment-request').find({
          query: {
            $sort: {
              'payment_request.created_at': -1,
            },
            ...commonQuery,
            ...getParams(),
            $limit: 5000,
          },
        }),
      ),
  );

  const personSortDict: Record<string, string> = {
    sortByPersonId: 'id',
    sortByPersonFirstName: 'first_name',
    sortByPersonLastName: 'last_name',
  };

  const onSort = (columnKey: string, sortOrder: number) => {
    setSortField(columnKey);
    if (Object.keys(personSortDict).includes(columnKey)) {
      setSort({});
      setCustomSortFilter({
        sortByPersonProperty: {
          property: personSortDict[columnKey],
          sortOrder: sortOrder === 1 ? 'ASC' : 'DESC',
        },
      });
    } else if (columnKey === 'sortByExportedAt') {
      setSort({});
      setCustomSortFilter({
        sortByExportedAt: sortOrder === 1 ? 'ASC' : 'DESC',
      });
    } else if (columnKey === 'sortByCompanyName') {
      setSort({});
      setCustomSortFilter({
        sortByCompanyName: sortOrder === 1 ? 'ASC' : 'DESC',
      });
    } else {
      setCustomSortFilter({});
      setSort({ [columnKey]: sortOrder });
    }
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <AdminAdministrationPaymentConfirmationContainer
        paymentRequestData={paymentRequestData as Paginated<PaymentRequestData>}
        isLoading={isLoading}
        pageTitle={pageTitle}
        onParamsChange={setOnlyUnresolved}
        onChangePage={onChangePage}
        onDateChange={setDateRange}
        onSearch={onSearch}
        onSort={onSort}
        sortField={sortField}
        fetchCSVData={
          fetchCSVData
        }
        onSubmitPayoutAmount={onSubmitPayoutAmount}
        payoutAmount={payoutAmount}
      />
    </Container>
  );
};

export default AdminAdministrationPaymentConfirmationPage;
