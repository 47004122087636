import { Query } from '@feathersjs/feathers';
import moment from 'moment/moment';
import { useQuery } from 'react-query';

import ResendMpsvRequestButton from './components/ResendMpsvRequestButton';
import feathersClient from '../../../apiClient';
import { getPaginatedResponse } from '../../../apiClient/utils';
import CompanyName from '../../../components/dataEntity/CompanyName';
import PersonName from '../../../components/dataEntity/PersonName';
import Spinner from '../../../components/Spinner';
import { Table, TableCell, TableHead, TablePagination, TableRow, useTablePagination } from '../../../components/Table';
import { TableHeadCellSortable } from '../../../components/Table/TableHeadCellSortable';
import useTableSort from '../../../components/Table/useTableSort';
import { TyTable, TyTableCell, TyTableHead, TyTablePagination, TyTableRow } from '@tymbe/ty-components/table';

interface MpsvScheduledPageProps {
  query?: Query;
}

const MpsvScheduledPage = ({ query = {} }: MpsvScheduledPageProps) => {
  const { addSort, sortString: sort } = useTableSort({ initialSortField: 'shift.start_time', initialSort: -1 });
  const { setPageParams, pageSize, pageStart } = useTablePagination();
  const [field, sortOrder] = sort?.split('=') ?? ['mpsvReport.created_at', -1];
  const finalQuery: Query = {
    ...query,
    $sort: Number(sortOrder) ? { [field]: Number(sortOrder) } : undefined,
    $limit: pageSize,
    $skip: pageStart,
  };

  const { data, isLoading } = useQuery({
    queryKey: ['mpsv', 'failed', finalQuery] as const,
    queryFn: async ({ queryKey: [,,q] }) => {
      const result = await feathersClient
        .service('application')
        .find({
          query: {
            $eager: '[person.personData, employer, mpsvReport, shift.[branchoffice.parent]]',
            ...q,
            $modify: { ...q?.$modify, mpsvScheduled: '' },
            $sort: {
              ...q.$sort,
              'person.id': 1,
            },
          },
        });
      return getPaginatedResponse(result);
    },
  });

  return (
    <div>
      <TyTable className="w-full [&_td:first-child]:border-r-transparent">
        <TableHead>
          <TyTableRow>
            <TyTableCell className="w-7" />
            <TableHeadCellSortable {...addSort('person_id')}>Tymber ID</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('person.last_name')}>Tymber</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('employer_id')}>Firma</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('shift.start_time')}>Nástup</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('shift:branchoffice.parent_id')}>Pracoviště</TableHeadCellSortable>
            <TyTableHead />
          </TyTableRow>
        </TableHead>
        <tbody>
          {isLoading && <TyTableRow><TyTableCell /><TyTableCell colSpan={6} align="center"><Spinner show={isLoading} className="w-6" /></TyTableCell></TyTableRow>}
          {data?.data.length === 0 ? <TyTableRow><TyTableCell /><TyTableCell colSpan={6} align="center">Žádná data</TyTableCell></TyTableRow> : null}
          {data?.data.map((item) => (
            <TyTableRow
              className="h-10"
              key={item.id}
            >
              <TyTableCell className="!bg-transparent" />
              <TyTableCell>{item.person_id}</TyTableCell>
              <TyTableCell><PersonName person_id={item.person_id} link /></TyTableCell>
              <TyTableCell><CompanyName company_id={item.employer_id} link /></TyTableCell>
              <TyTableCell>{moment(item.shift?.start_time).format('DD.MM.YYYY')}</TyTableCell>
              <TyTableCell>{item.shift?.branchoffice?.parent?.name}</TyTableCell>
              <TyTableCell>
                <div className="flex flex-row gap-1 justify-end">
                  <ResendMpsvRequestButton applicationId={item.id} />
                </div>
              </TyTableCell>
            </TyTableRow>
          ))}
        </tbody>
      </TyTable>
      <TablePagination
        {...setPageParams()}
        rowsCount={data?.total}
      />
    </div>
  );
};

export default MpsvScheduledPage;
