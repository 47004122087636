import { PersonDocumentData } from '@tymbe/schema/person-document.interface';
import { Paginated, Query } from '@feathersjs/feathers';
import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import {
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableSortHeader, useTablePagination,
} from '../../../components/Table';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import { PageTitle } from '../../../components/texts';
import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';

import columnHeaders from './document.utils';

const DocumentsPage = () => {
  const [sortField, setSortField] = useState('created_at');
  const [sortAscendingOrder, setSortAscendingOrder] = useState<number>(1);
  const { setPageParams, pageStart: paginationStart, pageSize: paginationPageSize } = useTablePagination();

  const query: Query = {
    $eager: '[person, documentType]',
    $joinRelation: '[documentType]',
    approved: { $null: true },
    $skip: paginationStart,
    $limit: paginationPageSize,
    $sort: { [sortField]: sortAscendingOrder },
  };

  const { data } = useQuery(
    ['person-document', query],
    ({ queryKey }): Promise<Paginated<PersonDocumentData>> => (feathersClient
      .service('person-document')
      .find({ query: queryKey[1] as Query })) as Promise<Paginated<PersonDocumentData>>,
  );

  const setSort = (field: string, sortOrder: number) => {
    if (field !== sortField) {
      setSortField(field);
    }
    setSortAscendingOrder(sortOrder);
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <>
          <div className="items-center flex justify-between">
            <PageTitle>Dokumenty</PageTitle>
          </div>
          <div className="bg-bg">
            <Table>
              <TableHead>
                <TableRow>
                  {columnHeaders.map((col) => (
                    <TableSortHeader
                      key={columnHeaders.indexOf(col).toString()}
                      field={col.sortable ? col.value : undefined}
                      activeField={sortField}
                      onSort={setSort}
                    >
                      {col.label}
                    </TableSortHeader>
                  ))}
                </TableRow>
              </TableHead>
              <tbody>
                {data?.data.map((item) => (
                  <TableRow
                    key={item.id.toString()}
                    cols={[
                      <Link
                        target="_blank"
                        to={{
                          pathname: `/user/${item.person?.id}`,
                        }}
                      >
                        {item.person?.id}
                      </Link>,
                      item.person?.first_name.concat(' ', item.person?.last_name),
                      item.documentType?.name,
                      moment(item.created_at).format('DD.MM.YYYY HH:mm'),
                    ]}
                  />
                ))}
              </tbody>
            </Table>
          </div>
          <TablePagination
            {...setPageParams()}
            rowsCount={data?.total}
          />
        </>
      </Wrapper>
    </Container>
  );
};

export default DocumentsPage;
