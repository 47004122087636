import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import feathersClient from '../../apiClient';

interface CompanyNameProps {
  company_id: number;
  link?: boolean;
  preferName?: boolean;
}

const CompanyName = ({ company_id, link, preferName }: CompanyNameProps) => {
  const { data } = useQuery({
    queryKey: ['company', company_id] as const,
    queryFn: ({ queryKey: [service, id] }) => feathersClient.service(service).get(id),
    enabled: !!company_id,
    refetchOnMount: false,
  });

  const name = preferName ? data?.name || data?.display_name : data?.display_name || data?.name;

  return link ? <Link to={`/company/${company_id}`}>{name}</Link> : <>{name}</>;
};

export default CompanyName;
