import { MpsvReportStatus } from '@tymbe/schema/enums';
import moment from 'moment';
import { useQuery } from 'react-query';

import ChangeMpsvStatusButton from './components/ChangeMpsvStatusButton';
import ShowMpsvRequestButton from './components/ShowMpsvRequestButton';
import StornoMpsvButton from './components/StornoMpsvButton';
import feathersClient from '../../../apiClient';
import { getPaginatedResponse } from '../../../apiClient/utils';
import CompanyName from '../../../components/dataEntity/CompanyName';
import PersonName from '../../../components/dataEntity/PersonName';
import Spinner from '../../../components/Spinner';
import {
  Table,
  TableCell,
  TableHead, TablePagination, TableRow,
  useTablePagination,
} from '../../../components/Table';
import { TableHeadCellSortable } from '../../../components/Table/TableHeadCellSortable';
import { TableRowExpandable } from '../../../components/Table/TableRowExpandable';
import useTableSort from '../../../components/Table/useTableSort';
import { TyTable, TyTableCell, TyTableHead, TyTableRow } from '@tymbe/ty-components/table';
import { TyTableRowExpandable } from './components/TyTableRowExpandable';

const MpsvSentPage = ({ query = {} }) => {
  const { addSort, sortString: sort } = useTableSort({ initialSortField: 'mpsvReport.created_at', initialSort: -1 });
  const { setPageParams, pageSize, pageStart } = useTablePagination();
  const [field, sortOrder] = sort?.split('=') ?? ['mpsvReport.created_at', -1];

  const { data, isLoading } = useQuery({
    queryKey: ['mpsv', 'sent', { ...query, $sort: Number(sortOrder) ? { [field]: Number(sortOrder) } : undefined, $limit: pageSize, $skip: pageStart }] as const,
    queryFn: async ({ queryKey: [,,q] }) => {
      const result = await feathersClient
        .service('application')
        .find({
          query: {
            $eager: '[person.personData, employer, mpsvReport, shift.[branchoffice.parent]]',
            $joinRelation: '[shift.branchoffice.parent, mpsvReport, person]',
            'mpsvReport.status': { $nin: [MpsvReportStatus.ERROR] },
            ...q,
            $sort: {
              ...q.$sort,
              'person.id': 1,
            },
          },
        });
      return getPaginatedResponse(result);
    },
  });

  return (
    <div>
      <TyTable className="w-full [&_td:first-child]:border-r-transparent">
        <TableHead>
          <TyTableRow>
            <TyTableCell className="w-7" />
            <TableHeadCellSortable {...addSort('person_id')}>Tymber ID</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('person.last_name')}>Tymber</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('employer_id')}>Firma</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('shift.start_time')}>Nástup</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('shift:branchoffice.parent_id')}>Pracoviště</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('mpsvReport.status')}>Status</TableHeadCellSortable>
            <TableHeadCellSortable {...addSort('mpsvReport.created_at')}>Vznik hlášení</TableHeadCellSortable>
            <TyTableHead />
          </TyTableRow>
        </TableHead>
        <tbody>
          {isLoading && <TyTableRow><TyTableCell /><TyTableCell colSpan={8} align="center"><Spinner show={isLoading} className="w-6" /></TyTableCell></TyTableRow>}
          {data?.data.length === 0 ? <TyTableRow><TyTableCell /><TyTableCell colSpan={8} align="center">Žádná data</TyTableCell></TyTableRow> : null}
          {data?.data.map((item) => (
            <TyTableRowExpandable
              className="bg-bg h-10"
              key={item.id}
              cols={[
                (expanded, cantExpand) => (cantExpand ? '' : expanded ? '˅' : '˃'),
                item.person_id,
                <PersonName person_id={item.person_id} link />,
                <CompanyName company_id={item.employer_id} link />,
                moment(item.shift?.start_time).format('DD.MM.YYYY'),
                item.shift?.branchoffice?.parent?.name,
                item.mpsvReport?.[0]?.status?.toUpperCase(),
                moment(item.mpsvReport?.[0]?.created_at).format('DD.MM.YYYY HH:mm'),
                () => {
                  if (!item.mpsvReport?.[0]) return null;
                  return (
                    <div className="flex flex-row gap-1 justify-end">
                      <ShowMpsvRequestButton mpsvReport={item.mpsvReport[0]} />
                      <ChangeMpsvStatusButton mpsvReport={item.mpsvReport[0]} />
                      <StornoMpsvButton applicationId={item.id} mpsvReport={item.mpsvReport[0]} />
                    </div>
                  );
                },
              ]}
              cantExpand={!item.mpsvReport?.[0].response_data && !item.mpsvReport?.[0].error?.length}
            >
              <TyTableCell />
              <TyTableCell colSpan={8}>
                <div hidden={!item.mpsvReport?.[0].response_data}>
                  <h3>MPSV odpověď:</h3>
                  <pre className="overflow-auto w-0 min-w-full">{item.mpsvReport?.[0].response_data}</pre>
                </div>
                <div hidden={!item.mpsvReport?.[0].error?.length} className="relative flex flex-col">
                  <h3>MPSV chyba:</h3>
                  <pre className="overflow-auto w-0 min-w-full">{JSON.stringify(item.mpsvReport?.[0].error, null, 2)}</pre>
                </div>
              </TyTableCell>
            </TyTableRowExpandable>
          ))}
        </tbody>
      </TyTable>
      <TablePagination
        {...setPageParams()}
        rowsCount={data?.total}
      />
    </div>
  );
};

export default MpsvSentPage;
