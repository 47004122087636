import { Editor, EditorProps } from '@toast-ui/react-editor';
import { FieldProps, useField } from 'informed';
import { useEffect, useRef } from 'react';

import '@toast-ui/editor/dist/toastui-editor.css';

interface OwnProps extends EditorProps {
  id?: string,
  label?: string,
}

export type TyMarkdownEditorProps<Fields extends object> = FieldProps<OwnProps, string, Fields>;

const TyMarkdownEditor = <Fields extends object>(props : TyMarkdownEditorProps<Fields>) => {
  const editorRef = useRef<Editor>(null);
  const {
    userProps,
    fieldApi,
    fieldState,
    render,
  } = useField<OwnProps, string>({ ...props });
  const {
    id,
    label,
    initialEditType = 'wysiwyg',
    hideModeSwitch = true,
    ...rest
  } = userProps;

  const { setValue, reset, getPristine } = fieldApi;

  useEffect(() => {
    if (getPristine()) {
      editorRef.current?.getInstance().setMarkdown(props.initialValue ?? '');
      reset({ resetDirt: true, resetTouched: true, value: props.initialValue ?? '' });
    }
  }, [props.initialValue, reset, getPristine]);

  return render(
    <div id={id}>
      <h4>{label}</h4>
      <Editor
        initialEditType={initialEditType}
        hideModeSwitch={hideModeSwitch}
        onChange={() => setValue(editorRef.current?.getInstance().getMarkdown())}
        ref={editorRef}
        {...rest}
      />
      {fieldState.error ? <div className="text-error-500 mt-2">{fieldState.error}</div> : ''}
    </div>,
  );
};

export default TyMarkdownEditor;
