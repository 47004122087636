import { useRef, cloneElement, useEffect, useCallback, ReactElement, ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';

type NavTabProps = Omit<ComponentPropsWithoutRef<'div'>>;

const NavTabs = ({ children, className, ...rest }: NavTabProps) => {
  const tabIndicatorRef = useRef<HTMLDivElement>(null);
  const tabWrapperRef = useRef<HTMLDivElement>(null);

  const setIndicator = useCallback(
    (left: number, width: number) => {
      if (!tabIndicatorRef.current) return;

      tabIndicatorRef.current.style.left = `${left}px`;
      tabIndicatorRef.current.style.width = `${width}px`;
    },
    [],
  );

  const tabs = [children].flat().map((child) =>
    cloneElement(child, {
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        child.props.onClick?.(e);
        if (!tabIndicatorRef.current) return;
        setIndicator(e.currentTarget.offsetLeft, e.currentTarget.clientWidth);
      },
    }));

  useEffect(() => {
    if (!tabWrapperRef.current || !tabIndicatorRef.current) return;

    const active =
      tabWrapperRef.current.querySelector<HTMLElement>(':scope > *.active');

    if (!active) return;

    setIndicator(active.offsetLeft, active.clientWidth);
  }, [children, setIndicator]);

  return (
    <div className={classNames('flex relative gap-10', className)} ref={tabWrapperRef} {...rest}>
      {tabs}
      <div
        className="absolute h-0.5 rounded transition-all duration-500 ease-in-out bg-primary top-full mt-1.5 left-0"
        ref={tabIndicatorRef}
      />
    </div>
  );
};

export default NavTabs;
