import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import feathersClient from '../../apiClient';

interface PersonNameProps {
  person_id: number;
  link?: boolean;
}

const PersonName = ({ person_id, link }: PersonNameProps) => {
  const { data } = useQuery({
    queryKey: ['person', person_id] as const,
    queryFn: ({ queryKey: [service, id] }) => feathersClient.service(service).get(id),
    enabled: !!person_id,
    refetchOnMount: false,
  });

  const name = `${data?.first_name} ${data?.last_name}`;

  return link ? <Link to={`/user/${person_id}`}>{name}</Link> : <>{name}</>;
};

export default PersonName;
