import { Form, FormApi, FormState } from 'informed';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import feathersClient from '../../../../apiClient';
import { ErrorAlert } from '../../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../../components/buttons';
import { getValuesForPatch } from '../../../../components/forms/form-util';
import TyInput from '../../../../components/inputs/TyInput';
import ModalShell from '../../../../components/modals/ModalShell';
import { RequestBody } from '../../../../types/ReactQuery';
import { ApplicationData } from '../../../../types/TymbeApi';
import { DeepPartial } from '../../../../types/utils';
import { isBetween } from '../../../../utils/validations';

export interface EditApplicationProps {
  application: ApplicationData;
  onSuccess?: (data: void, variables: any, context: unknown) => void | Promise<unknown>;
}

const EditApplication = ({ application, onSuccess }: EditApplicationProps) => {
  const [showModal, setShowModal] = useState(false);
  const formApiRef = useRef<FormApi<Partial<ApplicationData>>>();

  const { mutateAsync: patchApplication } = useMutation(
    ['editApplication'],
    async (req: RequestBody<DeepPartial<ApplicationData>>): Promise<void> => {
      await feathersClient.service('application').patch(req.id, req.body);
    },
    {
      onSuccess,
    },
  );

  const { data: applicationInfo } = useQuery(
    ['applicationDefaultSettings', application.id],
    async () => feathersClient.service('application').get(application.id, {
      query: {
        $eager: '[shift.company.defaultSettings]',
      },
    }),
  );

  const initialValues = {
    payment_base: application?.payment_base,
    credits: application?.credits,
  };

  const onSubmit = async (formState: FormState<Partial<ApplicationData>>) => {
    const values = getValuesForPatch(formState);
    const applicationFormData: DeepPartial<ApplicationData> = {
      payment_base: Number(values.payment_base) || undefined,
      credits: Number(values.credits) || undefined,
    };

    try {
      await patchApplication({ id: application.id, body: applicationFormData });
      setShowModal(false);
    } catch {
      ErrorAlert('Při editaci přihlášky došlo k chybě');
    }
  };

  return (
    <>
      <div className="p-2 text-right">
        <SecondaryButton onClick={() => setShowModal(true)}>
          Upravit cenu přihlášky
        </SecondaryButton>
      </div>
      <ModalShell showModal={showModal} onClose={() => setShowModal(false)}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          formApiRef={formApiRef}
        >
          <h3 className="ty-h3 text-align-start">
            Upravit cenu přihlášky
          </h3>
          <div className="flex flex-row gap-2 flex-wrap">

            <TyInput
              name="payment_base"
              type="number"
              label="Základní odměna"
              className="w-full md:flex-1"
              validate={(value: number) => (formApiRef.current?.getFormState().dirt.payment_base
                ? isBetween(
                  applicationInfo?.shift?.company?.defaultSettings?.min_payment_base,
                  applicationInfo?.shift?.company?.defaultSettings?.max_payment_base,
                )(value) : undefined)}
              required
            />

            <TyInput
              name="credits"
              label="Kredity"
              className="w-full md:flex-1"
              type="number"
              validate={(value: number) => (formApiRef.current?.getFormState().dirt.credits
                ? isBetween(
                  applicationInfo?.shift?.company?.defaultSettings?.min_credits,
                  applicationInfo?.shift?.company?.defaultSettings?.max_credits,
                )(value) : undefined)}
              required
            />
          </div>
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default EditApplication;
