import { ShiftTemplateData } from '@tymbe/schema/src/shift-template.interface';
import { ShiftData } from '@tymbe/schema/src/shift.interface';
import { FormState } from 'informed';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';

import feathersClient from '../../../../../apiClient';
import { getValuesForPatch } from '../../../../../components/forms/form-util';
import { CompanyFormValues } from '../../../components/CompanyForm';

export interface AffectedListProps {
  companyId: string;
  values: FormState<CompanyFormValues> | undefined
}
const AffectedList = ({ companyId, values }: AffectedListProps) => {
  const modifiedValues = values ? getValuesForPatch(values) : undefined;

  const { data: affectedShifts } = useQuery(
    ['getShifts'],
    async () =>
      feathersClient.service('shift').find({
        query: {
          $eager: 'branchoffice.parent',
          company_id: companyId,
          $or: [
            { payment_base: { $lt: modifiedValues?.min_payment_base } },
            { payment_base: { $gt: modifiedValues?.max_payment_base } },
            { credits: { $lt: modifiedValues?.min_credits } },
            { credits: { $gt: modifiedValues?.max_credits } },
            { credits_min: { $lt: modifiedValues?.min_credits } },
            { credits_min: { $gt: modifiedValues?.max_credits } },
            { invitation_credits: { $lt: modifiedValues?.min_credits } },
            { invitation_credits: { $gt: modifiedValues?.max_credits } },
          ],
        },
      }),
  );

  const { data: affectedShiftTemplates } = useQuery(
    ['getShiftTemplates'],
    async () =>
      feathersClient.service('shift-template').find({
        query: {
          company_id: companyId,
          $or: [
            { payment_base: { $lt: modifiedValues?.min_payment_base } },
            { payment_base: { $gt: modifiedValues?.max_payment_base } },
            { credits: { $lt: modifiedValues?.min_credits } },
            { credits: { $gt: modifiedValues?.max_credits } },
            { credits_min: { $lt: modifiedValues?.min_credits } },
            { credits_min: { $gt: modifiedValues?.max_credits } },
            { invitation_credits: { $lt: modifiedValues?.min_credits } },
            { invitation_credits: { $gt: modifiedValues?.max_credits } },
          ],
        },
      }),
  );

  return (
    <div>
      <h3>Pracovní pozice</h3>
      <table className="ty-print-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Název</th>
            <th>Alias</th>
          </tr>
        </thead>
        <tbody>
          {affectedShiftTemplates?.data.map((st: ShiftTemplateData) => (
            <Fragment key={st.id}>
              <tr style={{ borderBottom: '1px solid grey', height: '25px' }}>
                <td>{st.id}</td>
                <td>{st.template_name}</td>
                <td>{st.name}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      <h3>Směny</h3>
      <table className="ty-print-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Datum</th>
            <th>Čas od</th>
            <th>Provozovna</th>
          </tr>
        </thead>
        <tbody>
          {affectedShifts?.data.map((s: ShiftData) => (
            <Fragment key={s.id}>
              <tr style={{ borderBottom: '1px solid grey', height: '25px' }}>
                <td>{s.id}</td>
                <td>{moment(s?.start_time).format('DD.MM.')}</td>
                <td>{moment(s?.start_time).format('HH:mm')}</td>
                <td>{s.branchoffice?.parent?.name}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AffectedList;
