import { CompanyAutocompleteData } from '@tymbe/schema/company-autocomplete.interface';
import { GroupBase } from 'react-select';

import TyAsyncSelect, { TyAsyncSelectProps } from './TyAsyncSelect';
import feathersClient from '../../apiClient';
import { getPaginatedResponse } from '../../apiClient/utils';
import { ErrorAlert } from '../alerts';

type ProvidedProps = 'loadOptions' | 'getOptionLabel' | 'getOptionValue';
export type Option = CompanyAutocompleteData;

type InternalProps<Fields extends object> =
  TyAsyncSelectProps<Fields, CompanyAutocompleteData, GroupBase<CompanyAutocompleteData>, void, boolean>;

type TySelectCompanyProps<Fields extends object> =
  Omit<InternalProps<Fields>, ProvidedProps>;

const loadCompanyOptions = async (
  search: string,
) => {
  if (!search) {
    return {
      options: [],
      hasMore: false,
    };
  }
  let response;
  try {
    response = await feathersClient.service('company-autocomplete')
      .find({
        query: { search },
      })
      .then(getPaginatedResponse);
  } catch (err) {
    ErrorAlert('Došlo k chybě');
  }

  return {
    options: response?.data || [],
    hasMore: false,
  };
};

const TySelectCompanyAutocomplete = <Fields extends object>({
  debounceTimeout = 1000,
  defaultOptions = [],
  ...props
}: TySelectCompanyProps<Fields>) =>
  (
    <TyAsyncSelect<Fields, Option, GroupBase<Option>, void, boolean>
      {...props}
      debounceTimeout={debounceTimeout}
      defaultOptions={defaultOptions}
      loadOptions={loadCompanyOptions}
      getOptionLabel={(option) => `${option.name}, ${option.address.data?.full}`}
      getOptionValue={(option) => `${option}`}
    />
  );
export default TySelectCompanyAutocomplete;
