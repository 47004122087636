import { useMutation, useQuery, useQueryClient } from 'react-query';

import feathersClient from '../../../../apiClient';
import { CheckmarkIcon } from '../../../../components/icons';
import { ApplicationExcuseData } from '@tymbe/schema/src/application-excuse.interface';
import {
  TyTable,
  TyTableRow,
  TyTableFooter,
  TyTablePagination,
  TyTableSortHeader,
  NoDataTableRow,
} from '@tymbe/ty-components/table';
import { PrimaryButton } from '@tymbe/ty-components/button';
import defaultPageSizeOptions from '../../../../components/Table/table.utils';
import { Form, FormState } from 'informed';
import { ApplicationRow, DateColumnGetterProps } from './ApplicationRow';
import { AbsenceCategory } from '@tymbe/schema/enums';
import moment from 'moment';
import useURLParamsHandler from '../../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { toast } from 'react-toastify';
import { useUser } from '../../../../apiClient/ApiContext';
import { getPaginatedResponse } from '../../../../apiClient/utils';

type Props = {
  queryId?: string;
  query?: { [key: string]: any };
  headers: {
    value: string;
    label: string;
    sortable: boolean;
  }[];
  dateColumnGetter: DateColumnGetterProps;
};

type FormProps = {
  applications: {
    [key: number]: Partial<ApplicationExcuseData>;
  };
};

export const UnexcusedAbsences = ({
  queryId,
  query,
  headers,
  dateColumnGetter,
}: Props) => {
  const [{ allSearchParams }] = useURLParamsHandler();
  const queryClient = useQueryClient();
  const sort = allSearchParams.sort ?? { created_at: -1 };
  const search = allSearchParams.search;
  const currentPage = allSearchParams.page ?? 1;
  const pageSize = allSearchParams.pageSize ?? defaultPageSizeOptions[0].value;
  const user = useUser();

  const { data: applications, isLoading: getIsLoading } = useQuery(
    [queryId, pageSize, currentPage, sort, search],
    () =>
      feathersClient
        .service('application')
        .find({
          query: {
            $skip: pageSize * (currentPage - 1),
            $limit: pageSize,
            $sort: sort,
            $eager:
              '[attendance, shift.company, person.[accountState, personData], lastExcuse]',
            $joinRelation: '[shift, person.personData]',
            $leftJoinRelation: 'excuses',
            ...query,
            $modify: {
              quickFilter: search?.split(' '),
              withDeleted: true,
              ...query?.$modify,
            },
          },
        })
        .then(getPaginatedResponse)
  );

  const { mutateAsync: createExcuses, isLoading: createIsLoading } =
    useMutation(
      ['CreateExcuses'],
      async (req: FormProps): Promise<ApplicationExcuseData[]> => {
        const excuses = Object.entries(req.applications).map(
          ([applicationId, excuse]) => {
            return {
              application_id: Number(applicationId),
              category: AbsenceCategory.OTHER,
              state: excuse.state!,
              reviewed_at: moment.tz('Europe/Prague').toISOString(),
              reviewed_by: user.id,
              admin_note: excuse.admin_note,
            };
          }
        );

        return feathersClient.service('application-excuse').create(excuses);
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([queryId]);
          toast.success(`Absence úspešne zpracovány (${data.length})`);
        },
        onError: (error) => {
          toast.error(`Nastala chyba: ${error}`);
        },
      }
    );

  const onSubmit = (formState: FormState<FormProps>) => {
    createExcuses(formState.values);
  };

  return (
    <Form<FormProps> onSubmit={onSubmit}>
      <TyTable>
        <tbody>
          <TyTableRow>
            {headers.map((col, idx) => (
              <TyTableSortHeader
                key={idx.toString()}
                field={col.sortable ? col.value : undefined}
                className="border-x-0"
              >
                {col.label}
              </TyTableSortHeader>
            ))}
          </TyTableRow>
          {applications?.data.map((application) => (
            <ApplicationRow
              application={application}
              key={application.id}
              dateColumnGetter={dateColumnGetter}
            />
          ))}
          <NoDataTableRow
            visible={applications?.data.length === 0}
            colSpan={headers.length}
          />
        </tbody>
      </TyTable>
      <TyTablePagination rowsCount={applications?.total} />
      <TyTableFooter className="flex justify-center">
        <PrimaryButton
          loading={getIsLoading || createIsLoading}
          disabled={!applications?.data.length}
        >
          <CheckmarkIcon className="mr-2" />
          Uložit akce
        </PrimaryButton>
      </TyTableFooter>
    </Form>
  );
};
