import { BAD_JOB_EVALUATION_SCORE, DEFAULT_JOB_EVALUATION_SCORE, GOOD_JOB_EVALUATION_SCORE } from '../../../utils/constants';
import { AttendanceResolution, AccountState } from '../../../utils/enums';

export const scoreSearchValues = [
  {
    value: GOOD_JOB_EVALUATION_SCORE,
    label: 'palec hore',
  },
  {
    value: BAD_JOB_EVALUATION_SCORE,
    label: 'palec dole',
  },
  {
    value: DEFAULT_JOB_EVALUATION_SCORE,
    label: 'nehodnoceno',
  },
  {
    value: AttendanceResolution.ADMIN_EXCUSED_ABSENCE,
    label: 'omluvená absence od admina',
  },
  {
    value: AttendanceResolution.EXCUSED_ABSENCE,
    label: 'omluvená absence',
  },
  {
    value: AttendanceResolution.UNEXCUSED_ABSENCE,
    label: 'neomluvená absence',
  },
  {
    value: AttendanceResolution.CANCEL,
    label: 'storno',
  },

];

export type LabelsValues = Record<string, { name: string, label: string, format?: string }>;
export const labelsValues: LabelsValues = {
  dateYearFormat: {
    name: 'dateYearFormat',
    label: 'Rok',
    format: 'YYYY',
  },
  dateMonthFormat: {
    name: 'dateMonthFormat',
    label: 'Měsíc',
    format: 'MMMM',
  },
  dateMonthYearFormat: {
    name: 'dateMonthYearFormat',
    label: 'Měsíc a rok',
    format: 'MMMM YYYY',
  },
  shiftDateFrom: {
    name: 'shiftDateFrom',
    label: 'Datum směny od',
    format: 'DD.M.YYYY',
  },
  shiftDateTo: {
    name: 'shiftDateTo',
    label: 'Datum směny do',
    format: 'DD.M.YYYY',
  },
  shiftTimeFrom: {
    name: 'shiftTimeFrom',
    label: 'Směna od',
    format: 'HH:mm',
  },
  shiftTimeTo: {
    name: 'shiftTimeTo',
    label: 'Směna do',
    format: 'HH:mm',
  },
  position: {
    name: 'position',
    label: 'Pozice',
  },
  tymber: {
    name: 'tymber',
    label: 'Tymber',
  },
  company: {
    name: 'company',
    label: 'Firma',
  },
  branchoffice: {
    name: 'branchoffice',
    label: 'Provozovna',
  },
  department: {
    name: 'department',
    label: 'Oddělení',
  },
  score: {
    name: 'score',
    label: 'Hodnocení',
  },
  liability: {
    name: 'liability',
    label: 'Pouze směny s uděleným mankem',
  },
  internalName: {
    name: 'internalName',
    label: 'Interní označení',
  },
  confirmator: {
    name: 'confirmator',
    label: 'Docházku potvrdil/a',
  },
  confirmDateFrom: {
    name: 'confirmDateFrom',
    label: 'Datum potvrzení od',
    format: 'DD.M.YYYY',
  },
  confirmDateTo: {
    name: 'confirmDateTo',
    label: 'Datum potvrzení do',
    format: 'DD.M.YYYY',
  },
  orderId: {
    name: 'orderId',
    label: 'Id objednávky',
  },
  applicationId: {
    name: 'applicationId',
    label: 'Id směny',
  },
  attendanceId: {
    name: 'attendanceId',
    label: 'Id docházky',
  },
  requirements: {
    name: 'requirements',
    label: 'Podmínky',
  },
  orderType: {
    name: 'orderType',
    label: 'Stornované objednávky',
  },
  canceledOrders: {
    name: 'canceledOrders',
    label: 'Zahrnout',
  },
  uncanceledOrders: {
    name: 'uncanceledOrders',
    label: 'Nezahrnout',
  },
  onlyCanceledOrders: {
    name: 'onlyCanceledOrders',
    label: 'Jen stornované',
  },
  accountState: {
    name: 'accountState',
    label: 'Stav Účtu',
  },
  [AccountState.ACTIVE]: {
    name: AccountState.ACTIVE,
    label: 'Aktivní účet',
  },
  [AccountState.UNVERIFIED_PROOF_OF_IDENTITY]: {
    name: AccountState.UNVERIFIED_PROOF_OF_IDENTITY,
    label: 'Neověřený doklad totožnosti',
  },
  [AccountState.UNVERIFIED_ACCOUNT]: {
    name: AccountState.UNVERIFIED_ACCOUNT,
    label: 'Neověřený účet',
  },
  [AccountState.BAN]: {
    name: AccountState.BAN,
    label: 'Ban',
  },
  [AccountState.ADMIN]: {
    name: AccountState.ADMIN,
    label: 'Admin',
  },
  gender: {
    name: 'gender',
    label: 'Pohlaví',
  },
  male: {
    name: 'male',
    label: 'Muž',
  },
  female: {
    name: 'female',
    label: 'Žena',
  },
  userType: {
    name: 'userType',
    label: 'Typ uživatele',
  },
  userTypeTymber: {
    name: 'userTypeTymber',
    label: 'Jen tymbeři',
  },
  userTypeAll: {
    name: 'userTypeAll',
    label: 'Všichni uživatelé',
  },
  email: {
    name: 'email',
    label: 'Email',
  },
  phone: {
    name: 'phone',
    label: 'Telefon',
  },
  ageFrom: {
    name: 'ageFrom',
    label: 'Věk od',
  },
  ageTo: {
    name: 'ageTo',
    label: 'Věk do',
  },
  nationality: {
    name: 'nationality',
    label: 'Občanství',
  },
  socialSecurityNumber: {
    name: 'socialSecurityNumber',
    label: 'Rodné číslo',
  },
  address: {
    name: 'address',
    label: 'Adresa',
  },
  companyBlock: {
    name: 'companyBlock',
    label: 'Blokace ve firmě',
  },
  bankAccount: {
    name: 'bankAccount',
    label: 'Číslo účtu',
  },
  registrationDateFrom: {
    name: 'registrationDateFrom',
    label: 'Datum registrace od',
  },
  registrationDateTo: {
    name: 'registrationDateTo',
    label: 'Datum registrace do',
  },
  personNote: {
    name: 'personNote',
    label: 'Poznámka',
  },
  applicationState: {
    name: 'applicationState',
    label: 'Stav',
  },
  applicationPriceFrom: {
    name: 'applicationPriceFrom',
    label: 'Cena od',
  },
  applicationPriceTo: {
    name: 'applicationPriceTo',
    label: 'Cena do',
  },
  applicationCreditsFrom: {
    name: 'applicationCreditsFrom',
    label: 'Kredit od',
  },
  applicationCreditsTo: {
    name: 'applicationCreditsTo',
    label: 'Kredit do',
  },
  showSystemCancelled: {
    name: 'showSystemCancelled',
    label: 'Zobrazit zrušeno systémem',
  },
  mpsvReportStatus: {
    name: 'mpsvReportStatus',
    label: 'Status',
  },
  mpsvReportResponseStatus: {
    name: 'mpsvReportResponseStatus',
    label: 'Response status',
  },
};
