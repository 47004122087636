import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

import MpsvFailedPage from './failed.page';
import MpsvScheduledPage from './scheduled.page';
import MpsvHistoryPage from './sent.page';
import Card from '../../../components/Layout/Card';
import NavTabs from '../../../components/navTabs/NavTabs';
import { useTablePagination } from '../../../components/Table';
import { PageTitle } from '../../../components/texts';
import TyInteractiveLabels from '../../../components/TyAdvancedSearch/Components/TyInteractiveLabels';
import apiBuilder from '../../../components/TyAdvancedSearch/Libs/apiBuilder';
import { labelsValues } from '../../../components/TyAdvancedSearch/Libs/enums';
import TyAdvancedSearchMpsv from '../../../components/TyAdvancedSearch/Mpsv';
import apiTransformationsMpsv from '../../../components/TyAdvancedSearch/Mpsv/apiTransformationsMpsv';
import useSingleSearchString from '../../../hooks/UrlParamsHandler/useSingleSearchString';

const MpsvForeignerTablePage = () => {
  const [queryString, setQuery] = useSingleSearchString('search', '{}');
  const { setPageStart } = useTablePagination();
  const search = queryString ? JSON.parse(queryString) : {};
  const query = apiBuilder(search, apiTransformationsMpsv);

  const onAdvancedSearch = (values: object) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(
        ([, value]) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return value;
        },
      ),
    );
    setQuery(JSON.stringify(filteredValues));
    setPageStart(0);
  };

  return (
    <>
      <PageTitle>MPSV Tabulka</PageTitle>
      <Card className="my-6">
        <div className="flex align-center gap-10 flex-wrap">
          <NavTabs className="grow">
            <NavLink key="failed-tab" to="failed" className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Chyby</NavLink>
            <NavLink key="scheduled-tab" to="scheduled" className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Neodeslané</NavLink>
            <NavLink key="sent-tab" to="sent" className={({ isActive }) => (isActive ? 'text-secondary active' : 'text-secondary-200')}>Odeslané</NavLink>
          </NavTabs>
          <TyAdvancedSearchMpsv
            pageTitle="Pokročilé vyhledávání MPSV"
            className="shrink-0"
            setInitialValues={(srch) => onAdvancedSearch(srch ?? {})}
            initialValues={search}
          />
        </div>
        <div className="border-t border-t-fg-100 mt-6 [&>div>div]:!m-0 [&>div>div]:pt-6" hidden={!Object.keys(search).length}>
          <TyInteractiveLabels
            labelsValues={labelsValues}
            setInitialValues={onAdvancedSearch}
            initialValues={search}
          />
        </div>
      </Card>

      <Routes>
        <Route path="*" element={<Navigate to="failed" replace />} />
        <Route path="sent" element={<MpsvHistoryPage query={query} />} />
        <Route path="failed" element={<MpsvFailedPage query={query} />} />
        <Route path="scheduled" element={<MpsvScheduledPage query={query} />} />
      </Routes>

    </>
  );
};

export default MpsvForeignerTablePage;
