import { useCallback, useEffect } from 'react';
import { URLSearchParamsInit, useLocation, useSearchParams } from 'react-router-dom';

let lastUpdate = Date.now();
let lastSearchString: URLSearchParams | undefined;
const useSearchString = (initialValue?: URLSearchParamsInit) => {
  const [searchString, setSearchString] = useSearchParams(initialValue);
  const location = useLocation();

  const dedoopedSetSearchString = useCallback((value: (URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit))) => {
    setSearchString(
      () => {
        const currentSearchString = new URLSearchParams(window.location.search.slice(1));
        const newValue = typeof value === 'function' ? value(currentSearchString) : value;
        const newSearchString = new URLSearchParams(newValue as URLSearchParams);

        if (currentSearchString.toString() === newSearchString.toString() || location.state?.isBackground) {
          return currentSearchString;
        }
        return newValue;
      },
      { replace: Date.now() < (lastUpdate + 1000) },
    );
    lastUpdate = Date.now();
  }, [location, setSearchString]);

  useEffect(() => {
    lastUpdate = Date.now();
  }, []);

  return [searchString, dedoopedSetSearchString] as const;
};

export default useSearchString;
