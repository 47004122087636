import { MpsvReportData } from '@tymbe/schema/mpsv-report.interface';
import { ComponentProps, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import ButtonWithModal from './ButtonWithModal';
import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import Spinner from '../../../../components/Spinner';
import RefreshIcon from '../../../calendar/components/icons/RefreshIcon';

type ResendMpsvRequestButtonOwnProps = {
  applicationId: number
};

type ResendMpsvRequestButtonProps = ResendMpsvRequestButtonOwnProps & Omit<ComponentProps<'button'>, keyof ResendMpsvRequestButtonOwnProps>;

const ResendMpsvRequestButton = ({ applicationId, ...rest }: ResendMpsvRequestButtonProps) => {
  const queryClient = useQueryClient();
  const [isMutating, setIsMutating] = useState(false);

  const { mutateAsync: resendMpsvReport } = useMutation(
    ['resend-mpsv-report'],
    (id: number): Promise<MpsvReportData> =>
      feathersClient.service('mpsv/generate-report').create({ application_id: id }),
    {
      onMutate: () => {
        setIsMutating(true);
      },
      onError: async () => {
        await queryClient.invalidateQueries('mpsv');
        ErrorAlert('Nepodařilo se odeslat report.');
        setIsMutating(false);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries('mpsv');
        SuccessAlert('Report odeslán.');
        setIsMutating(false);
      },
    },
  );

  return (
    <ButtonWithModal
      title="Znovu odeslat request"
      message=""
      header="Vážně chcete znovu odeslat MPSV request?"
      icon={isMutating ? (<Spinner show className="w-3" />) : (<RefreshIcon className="w-3" />)}
      disabled={isMutating}
      onConfirm={() => {
        resendMpsvReport(applicationId);
      }}
      {...rest}
    />
  );
};

export default ResendMpsvRequestButton;
