import { PersonPerksData } from '@tymbe/schema/person-perks.interface';
import moment from 'moment';

import feathersClient from '../../../../apiClient';
import {
  ApplicationData,
  CompanyData,
  ManShiftData,
  PerkData,
  PerkId,
  PersonData,
  ShiftData,
} from '../../../../types/TymbeApi';

// eslint-disable-next-line import/prefer-default-export
export const enum NoFreeManShiftBehavior {
  Return = 'return',
  CreateNew = 'create_new',
  ThrowError = 'throw_error',
}

type Shift = Omit<ShiftData, 'perk' | 'company' | 'manShift'> & { perk: PerkData[] } & { company: CompanyData } & {
  manShift: (ManShiftData & { perk: PerkData[] })[] };

const getShift = async (
  tymberId: number,
  shiftId: number,
): Promise<Shift> => {
  try {
    return await feathersClient.service('shift').get(shiftId, {
      query: {
        $eager: '[company, perk, manShift.[perk,activeApplication]]',
        $joinRelation: '[company]',
        $leftJoinRelation: '[perk, manShift(notDeleted)]',
      },
    }) as Shift;
  } catch (error) {
    console.error(error);
    throw new Error(`Při načítání směny s ID ${shiftId} došlo k chybě`);
  }
};

const getApplication = async (
  tymberId: number,
  shiftId: number,
): Promise<ApplicationData> => {
  try {
    const [application] = await feathersClient.service('application').find({
      query: {
        $eager: 'shift',
        $joinRelation: '[shift]',
        person_id: tymberId,
        'shift.id': shiftId,
        $limit: -1,
      },
    }) as ApplicationData[];
    return application;
  } catch (error) {
    console.error(error);
    throw new Error('Při načítání přihlášky došlo k chybě');
  }
};

const getPerson = async (
  tymberId: number,
): Promise<PersonData & { personPerks: NonNullable<PersonData['personPerks']> }> => {
  try {
    return await feathersClient.service('person').get(tymberId, {
      query: {
        $eager: 'personPerks',
        $joinRelation: '[personPerks]',
      },
    }) as PersonData & { personPerks: NonNullable<PersonData['personPerks']> };
  } catch (error) {
    console.error(error);
    throw new Error(`Při načítání osoby s ID: ${tymberId} došlo k chybě`);
  }
};

const filterManShiftMissingPerks = (
  shift: ShiftData,
  manshiftPerks: PerkData[],
  personPerks: NonNullable<PersonData['personPerks']>,
) => manshiftPerks.filter((msPerk): boolean => {
  if (msPerk.id === PerkId.ANY_GENDER) return false;
  return !(
    msPerk.id === PerkId.ANY_QUALIFICATION
      || (msPerk.id === PerkId.MAN_ONLY && personPerks.man_only)
      || (msPerk.id === PerkId.WOMAN_ONLY && personPerks.woman_only)
      || (msPerk.id === PerkId.EU_CITIZEN && personPerks.eu_citizen)
      || (msPerk.id === PerkId.NON_UA_PROTECTED && !personPerks.ua_protected)
      || (msPerk.id === PerkId.ADULT && personPerks.adult)
      || (msPerk.id === PerkId.COMPANY_SENIOR && personPerks.worked_for_company.includes(shift.company_id))
      || (msPerk.id === PerkId.BRANCHOFFICE_SENIOR && personPerks.worked_for_branch.includes(shift.branchoffice_id))
      || (msPerk.id === PerkId.POSITION_SENIOR && personPerks.worked_at_position.includes(shift.shift_template_id))
      // PerkEnum.INFORMATION_CARD is not included, but it shouldn't ever be on a man-shift, only on shift
  );
});

const createNewManShift = async (shiftId: number): Promise<ManShiftData> => {
  const newManShiftData: Partial<ManShiftData> = {
    shift_id: shiftId,
  };
  const newManShift = await feathersClient.service('man-shift').create(newManShiftData);
  return newManShift;
};

const createNewApplication = async (
  shift: ShiftData,
  tymberId: number,
  manShiftId: number,
): Promise<ApplicationData> => {
  const newApplicationData: Partial<ApplicationData> = {
    state: null,
    invitation: true,
    man_shift_id: manShiftId,
    credits: Number(shift.invitation_credits),
    payment_base: Number(shift.payment_base),
    person_id: tymberId,
    employer_id: shift.company_id,
  };
  const application = await feathersClient.service('application').create(newApplicationData);
  return application;
};

const updateApplicationData = async (applicationId: number, manShiftId: number): Promise<ApplicationData> => {
  const updatedApplicationData: Partial<ApplicationData> = {
    state: null,
    invitation: true,
    man_shift_id: manShiftId,
  };
  const application = await feathersClient.service('application').patch(applicationId, updatedApplicationData);
  return application;
};

const getMostSuitedManShift = (shift: ShiftData, personPerks: PersonPerksData):
  { manShift: ManShiftData, missingPerks: PerkData[] } | undefined => {
  if (!shift.manShift) {
    throw new Error('Function `getMostSuitedManShift` requires shiftData to contain eagered manShifts');
  }

  const { manShift } = shift;

  // Exclude already filled man-shits from the algorithm, we don't want to overwrite them
  const freeManShifts = manShift.filter((ms) => !ms.activeApplication);
  // Sort all manShifts, in order of Highest amount of man-shift perks to Lowest amount of man-shift perks
  // so we can work the fact that the array of manshifts goes from the most restrictive to the least restrictive
  const sortedManShifts = freeManShifts
    .sort((a, b) => (b.perk?.length ?? 0) - (a.perk?.length ?? 0));

  const manShiftsWithMissingPerks: ({ manShift: ManShiftData, missingPerks: PerkData[] })[] = [];
  let idealManShift: ({ manShift: ManShiftData, missingPerks: PerkData[] }) | undefined;

  // Iterate over sorted manShifts and check wherever person has missing perks, on first 0 missing perks found, stop
  sortedManShifts.some((ms) => {
    if (!ms.perk) throw new Error('Function `getMostSuitedManShift` requires manShifts to contain eagered perks');

    // Generate array of person missing perks
    const missingPerks = filterManShiftMissingPerks(shift, ms.perk, personPerks);
    // If person is missing 0 perks, stop and use this man-shift
    if (missingPerks.length === 0) {
      idealManShift = { manShift: ms, missingPerks };
      return true;
    }

    // If person is missing perks, store the amount of missing perks for this man-shift and continue iteration
    manShiftsWithMissingPerks.push({ manShift: ms, missingPerks });
    return false;
  });

  // Step 1: Return the most restrictive man-shift with 0 missing perks (no perks needed cases included)
  if (idealManShift) return idealManShift;

  // Step 2: Sort the man-shifts: first item should be with the LOWEST amount of missing person perks and the LOWEST
  //         amount of required (man-shift) perks.
  const preparedManShifts = manShiftsWithMissingPerks.sort((a, b) => {
    const missingComparison = a.missingPerks.length - b.missingPerks.length;
    return missingComparison !== 0 ? missingComparison
      : (a.manShift.perk?.length ?? 0) - (b.manShift.perk?.length ?? 0);
  });

  return preparedManShifts[0] || undefined;
};

export const invite = async (tymberId: number, shiftId: number, canCreateManShift: NoFreeManShiftBehavior) => {
  const shift = await getShift(tymberId, shiftId);
  const person = await getPerson(tymberId);
  let application = await getApplication(tymberId, shiftId);

  if (application && [null, 'confirmed'].includes(application.state) && moment(application.deleted_at).isAfter(moment())) {
    return {
      noFreeManShift: false,
      applicationExists: true,
      application,
    };
  }
  let manShift: ManShiftData | undefined;

  if (shift.manShift?.length > 0) {
    const selectedManShift = getMostSuitedManShift(shift, person.personPerks);
    manShift = selectedManShift?.manShift;
  }
  if (!manShift) {
    if (shift.company.is_readonly) {
      throw new Error('Není volné místo (a vytvoření místa není povoleno)');
    }
    if (canCreateManShift === NoFreeManShiftBehavior.Return) {
      return {
        noFreeManShift: true,
      };
    }
    if (canCreateManShift === NoFreeManShiftBehavior.ThrowError) {
      throw new Error('Není volné místo (a vytvoření místa bylo zakázáno)');
    }
    if (canCreateManShift === NoFreeManShiftBehavior.CreateNew) {
      manShift = await createNewManShift(shiftId);
    } else {
      throw new TypeError(`Nečekaná hodnota canCreateManShift: ${canCreateManShift}`);
    }
  }

  if (application) {
    application = await updateApplicationData(application.id, manShift.id);
  } else {
    application = await createNewApplication(shift, tymberId, manShift.id);
  }

  return {
    application,
  };
};
