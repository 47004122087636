import useSingleSearchString from '../../hooks/UrlParamsHandler/useSingleSearchString';
import { ComponentPropsWithoutRef, MouseEvent, useCallback } from 'react';
import { TableCell, TableCellProps } from './TableCell';
import classNames from 'classnames';
import { TyTableCell, TyTableHead } from '@tymbe/ty-components/table';

const getNextSort = (sort: number) => {
  if (sort === 0) return 1;
  if (sort === 1) return -1;
  return 0;
};

const SortUpIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 320 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"
      fill="currentColor"
    />
  </svg>
);

const SortDownIcon = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 320 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.4 470.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H32c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
      fill="currentColor"
    />
  </svg>
);

interface TableHeadCellSortableProps extends Omit<TableCellProps, 'head'> {
  field: string;
  initialSort?: 1 | 0 | -1;
  group?: string;
  onSort?: (sort: number) => void;
}

export const TableHeadCellSortable = ({
  children,
  field,
  initialSort = 0,
  onClick,
  group = 'sort',
  onSort,
  ...props
}: TableHeadCellSortableProps) => {
  const [searchString, setSearchString] = useSingleSearchString(group, `${field}=${initialSort}`);
  const [fieldFromSearchString, sortFromSearchString] = searchString?.split('=') ?? [];
  let sort = fieldFromSearchString === field ? Number(sortFromSearchString) : initialSort;

  if (fieldFromSearchString !== field) {
    sort = 0;
  }

  const onClickInner = useCallback(
    (e: MouseEvent<HTMLTableCellElement>) => {
      onClick?.(e);
      const [lastField = field, lastSort = initialSort] = searchString?.split('=') ?? [];
      let nextSort = getNextSort(Number(lastSort));
      if (lastField !== field) {
        nextSort = getNextSort(Number(initialSort));
      }
      setSearchString(`${field}=${nextSort}`);
      onSort?.(nextSort);
    },
    [field, initialSort, onClick, onSort, searchString, setSearchString],
  );

  return (
    <TyTableHead {...props} onClick={onClickInner}>
      <div className="flex justify-content-between">
        {children}
        <div className="relative size-[15px] fill-danger">
          <SortUpIcon className={classNames('absolute', { hidden: sort === -1, 'text-primary': sort === 1 })} />
          <SortDownIcon className={classNames('absolute', { hidden: sort === 1, 'text-primary': sort === -1 })} />
        </div>
      </div>
    </TyTableHead>
  );
};
