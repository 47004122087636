import moment, { Moment } from 'moment';

import { FormValues } from '../Libs/transform';

const apiTransformationsMpsv = {

  company: (companies: FormValues[]) => {
    if (companies.length === 0) return undefined;
    const $query = { 'application.employer_id': { $in: companies.map((c) => c.value) } };

    return $query;
  },

  tymber: (tymbers: FormValues[]) => {
    if (tymbers.length === 0) return undefined;
    const $query = {
      'application.person_id': { $in: tymbers.map((t) => t.value) },
    };
    return $query;
  },

  branchoffice: (branchoffices: FormValues[]) => {
    if (branchoffices.length === 0) return undefined;
    const $query = {
      'shift:branchoffice:parent.id': { $in: branchoffices.map((b) => b.value) },
    };
    return $query;
  },

  department: (departments: FormValues[]) => {
    if (departments.length === 0) return undefined;
    const $query = {
      'shift.branchoffice_id': { $in: departments.map((d) => d.value) },
    };
    return $query;
  },

  mpsvReportStatus: (statuses: FormValues[]) => {
    if (statuses.length === 0) return undefined;
    const $query = {
      'mpsvReport.status': { $in: statuses.map((s) => s.value) },
    };
    return $query;
  },

  mpsvReportResponseStatus: (response_statuses: FormValues[]) => {
    if (response_statuses.length === 0) return undefined;
    const $query = {
      'mpsvReport.response_status': { $in: response_statuses.map((rs) => rs.value) },
    };
    return $query;
  },

  shiftTimeFrom:
    (time: Moment) => ({ $modify: { startTime: [moment(time).startOf('minutes').toISOString(), undefined] } }),

  shiftTimeTo:
    (time: Moment) => ({ $modify: { startTime: [undefined, moment(time).startOf('minutes').toISOString()] } }),

  shiftDateFrom: (date: Moment) => ({ $modify: { startDate: [moment(date).startOf('day').toISOString(), undefined] } }),

  shiftDateTo: (date: Moment) => ({ $modify: { startDate: [undefined, moment(date).endOf('day').toISOString()] } }),
};

export default apiTransformationsMpsv;
