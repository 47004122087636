import moment from 'moment';

import ShiftState from './ShiftStateEnum';
import { ApplicationData, ApplicationState } from '../../../../types/TymbeApi';
import { AttendanceResolution } from '../../../../utils/enums';

const shiftStateSwitch = (applicationInfo?: ApplicationData) => {
  if (applicationInfo?.attendance?.resolution === AttendanceResolution.OK) {
    return ShiftState.ATTENDANCE_OK;
  }
  if (applicationInfo?.attendance?.resolution === AttendanceResolution.CANCEL) {
    return ShiftState.ATTENDANCE_CANCEL;
  }
  if (applicationInfo?.attendance?.resolution === AttendanceResolution.BREACH_OF_DISCIPLINE) {
    return ShiftState.ATTENDANCE_BREACH_OF_DISCIPLINE;
  }
  if (applicationInfo?.attendance?.resolution === AttendanceResolution.ADMIN_EXCUSED_ABSENCE) {
    return ShiftState.ATTENDANCE_ADMIN_EXCUSED_ABSENCE;
  }
  if (applicationInfo?.attendance?.resolution === AttendanceResolution.EXCUSED_ABSENCE) {
    return ShiftState.ATTENDANCE_EXCUSED_ABSENCE;
  }
  if (applicationInfo?.attendance?.resolution === AttendanceResolution.UNEXCUSED_ABSENCE) {
    return ShiftState.ATTENDANCE_UNEXCUSED_ABSENCE;
  }
  if (applicationInfo?.state === ApplicationState.CONFIRMED
    && applicationInfo?.shift
    && new Date(applicationInfo?.shift.end_time).getTime() < Date.now()) {
    return ShiftState.SHIFT_HAPPENED;
  }
  if (applicationInfo?.state === ApplicationState.CONFIRMED) {
    return ShiftState.APPLICATION_CONFIRMED;
  }
  if (applicationInfo?.state === ApplicationState.REJECTED) {
    return ShiftState.APPLICATION_REJECTED;
  }
  if (applicationInfo?.state === ApplicationState.CANCELED_EARLY) {
    return ShiftState.APPLICATION_CANCELED_EARLY;
  }
  if (applicationInfo?.state === ApplicationState.CANCELED_LATE) {
    return ShiftState.APPLICATION_CANCELED_LATE;
  }
  if (applicationInfo?.state === ApplicationState.SYSTEM_CANCELED) {
    return ShiftState.APPLICATION_SYSTEM_CANCELED;
  }
  if (applicationInfo?.invitation && applicationInfo.state === null && moment(applicationInfo.deleted_at).isBefore(moment())) {
    return ShiftState.INVITATION_EXPIRED;
  }
  if (applicationInfo?.invitation) {
    return ShiftState.INVITATION;
  }
  return ShiftState.DEFAULT;
};
export default shiftStateSwitch;
