import { Paginated, Query } from '@feathersjs/feathers';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import objection from 'objection';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import feathersClient from '../../apiClient';
import {
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableSortHeader,
  useTablePagination,
} from '../../components/Table';
import defaultPageSizeOptions from '../../components/Table/table.utils';
import { PageTitle } from '../../components/texts';
// Advance filter -->
import TyInteractiveLabels from '../../components/TyAdvancedSearch/Components/TyInteractiveLabels';
import apiBuilder from '../../components/TyAdvancedSearch/Libs/apiBuilder';
import { labelsValues } from '../../components/TyAdvancedSearch/Libs/enums';
import TyAdvancedSearchMonthlyWage from '../../components/TyAdvancedSearch/MonthlyWage';
import apiTransformationsMonthlyWage from '../../components/TyAdvancedSearch/MonthlyWage/apiTransformationsMonthlyWage';
// Advance filter <--
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import useURLParamsHandler from '../../hooks/UrlParamsHandler/useURLParamsHandler';
import { MonthlyWageData } from '../../types/TymbeApi';

const pageTitle = 'Měsíční DPP';
const columnHeaders = [
  {
    value: 'year',
    label: 'Rok',
    sortable: true,
  },
  {
    value: 'month',
    label: 'Měsíc',
    sortable: true,
  },
  {
    value: 'employer_id',
    label: 'ID Firma',
    sortable: true,
  },
  {
    value: 'name',
    label: 'Název firmy',
    sortable: false,
  },
  {
    value: 'first_worked_day',
    label: 'Nástup',
    sortable: true,
  },
  {
    value: 'person_id',
    label: 'ID Tymber',
    sortable: true,
  },
  {
    value: 'first_name',
    label: 'Jméno',
    sortable: true,
  },
  {
    value: 'last_name',
    label: 'Příjmení',
    sortable: true,
  },
  {
    value: 'worked_time',
    label: 'Potvrzený čas',
    sortable: true,
  },
  {
    value: 'wage',
    label: 'Hrubá mzda',
    sortable: true,
  },
  {
    value: 'tax',
    label: 'Daň',
    sortable: false,
  },
  {
    value: 'netWage',
    label: 'Čistá mzda',
    sortable: false,
  },
];

const defaultSort = {
  year: -1,
  month: 1,
  last_name: 1,
};

const AdminTymberMonthlyWage = () => {
  const [{ allSearchParams, tyAdvanceSearch }, setUrlParamsHandler] = useURLParamsHandler();

  const { setPageParams, pageStart: paginationStart, pageSize: paginationPageSize } = useTablePagination();
  const [sortField, setSortField] = useState<string>('');
  const [sortAscendingOrder, setSortAscendingOrder] = useState<number>(-1);
  const [advanceSearch, setAdvanceSearch] = useState(tyAdvanceSearch);
  const [query, setQuery] = useState<Query>({});
  const { data } = useQuery(
    ['monthly-wage', query],
    async ({ queryKey }): Promise<Paginated<MonthlyWageData>> =>
      feathersClient.service('monthly-wage').find({ query: queryKey[1] as Query }),
    { enabled: !!query.$limit },
  );

  useEffect(() => {
    let q: Query = {
      $skip: paginationStart,
      $limit: paginationPageSize,
      $sort: allSearchParams.sort || defaultSort,
      $eager: '',
      worked_time: { $gt: 0 },
    };
    if (sortField) {
      q = { ...q, $sort: { [sortField]: sortAscendingOrder } };
    }
    if (advanceSearch) {
      setUrlParamsHandler(advanceSearch);
      q = {
        ...q,
        ...apiBuilder(
          advanceSearch,
          apiTransformationsMonthlyWage,
        ),
      };
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const relation = objection.RelationExpression.create(
      q.$eager,
    );
    const mergedRelation = relation.merge('[person, employer]');
    q.$eager = mergedRelation.toString();

    setQuery(q);
  }, [paginationPageSize, paginationStart, sortField, sortAscendingOrder, advanceSearch]);

  const setSort = (field: string, sortOrder: number) => {
    if (field !== sortField) {
      setSortField(field);
    }
    setSortAscendingOrder(sortOrder);
  };

  const getRow = (mw: MonthlyWageData): (string | number | React.ReactElement)[] =>
    [
      mw.year,
      mw.month,
      mw.employer_id,
      mw.employer!.name,
      mw.first_worked_day ? moment(mw.first_worked_day).format('DD-MM-YYYY') : '-',
      <Link
        target="_blank"
        to={{
          pathname: `/user/${mw.person_id}`,
        }}
      >
        {mw.person_id}
      </Link>,
      mw.first_name,
      mw.last_name,
      Number.parseFloat(mw.worked_time),
      Number.parseFloat(mw.wage),
      Number.parseFloat(mw.tax),
      Number.parseFloat(mw.netwage),
    ];
  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
      sidebarExtra
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <>
          <div className="items-center flex justify-between">
            <PageTitle>{pageTitle}</PageTitle>
          </div>
          <div className="bg-bg">
            <div className="px-2.5 text-center space-y-5">
              <div className="flex justify-end items-center mt-6 p-2">
                <TyAdvancedSearchMonthlyWage
                  pageTitle="Filtrování Měsíční DPP"
                  setInitialValues={setAdvanceSearch}
                  initialValues={advanceSearch}
                />
              </div>
              <TyInteractiveLabels
                labelsValues={labelsValues}
                setInitialValues={setAdvanceSearch}
                initialValues={advanceSearch}
              />
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  {columnHeaders.map((col) => (
                    <TableSortHeader
                      key={col.value}
                      field={col.sortable ? col.value : undefined}
                      activeField={sortField}
                      onSort={setSort}
                    >
                      {col.label}
                    </TableSortHeader>
                  ))}
                </TableRow>
              </TableHead>
              <tbody>
                {data?.data.map((mw) => (
                  <TableRow
                    key={`${mw.year}-${mw.month}-${mw.employer_id}-${mw.person_id}`}
                    cols={getRow(mw)}
                  />
                ))}
              </tbody>
            </Table>
          </div>
          <TablePagination
            {...setPageParams()}
            rowsCount={data?.total}
          />
        </>
      </Wrapper>
    </Container>
  );
};

export default AdminTymberMonthlyWage;
