export const isValidGeneralZipCode = (zipCode: string) => /^\d+$/.test(zipCode);

export const isValidCzechZipCode = (zipCode: string) => /^\d{5}$/.test(zipCode);

export const isValidEmail = (email: string) => /^[^@\s]+@[^@\s]+\.([a-z]{2,3})$/.test(email);

export const isValidBirthdate = (birthdate: string) => /^(?:0[1-9]|[12]\d|3[01])(\.)(?:0[1-9]|1[012])\1(?:19|20)\d{2}$/.test(birthdate);

export const isBetween = (min?: number, max?: number) => (inputValue: number) => {
  if (min === undefined || max === undefined) {
    return 'Chybí některá z hodnot z nastavení firmy.';
  }

  if (Number(inputValue) >= min && Number(inputValue) <= max) return undefined;
  return 'Hodnota musí být v rozsahu z nastavení firmy.';
};
