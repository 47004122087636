import { MpsvReportStatus } from '@tymbe/schema/enums';
import { MpsvReportData } from '@tymbe/schema/mpsv-report.interface';
import { ComponentProps, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import ChangeStatusModal from './ChangeStatusModal';
import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import Spinner from '../../../../components/Spinner';
import { RequestBody } from '../../../../types/ReactQuery';
import EditIcon from '../../../calendar/components/icons/EditIcon';

type ChangeMpsvStatusButtonOwnProps = {
  mpsvReport?: MpsvReportData;
};

type ChangeMpsvStatusButtonProps = ChangeMpsvStatusButtonOwnProps & Omit<ComponentProps<'button'>, keyof ChangeMpsvStatusButtonOwnProps>;

const ChangeMpsvStatusButton = ({ mpsvReport }: ChangeMpsvStatusButtonProps) => {
  const queryClient = useQueryClient();
  const [isMutating, setIsMutating] = useState(false);

  const { mutateAsync: patchMpsvReport } = useMutation(
    ['patch-mpsv-report'],
    (req: RequestBody<MpsvReportData>): Promise<MpsvReportData> =>
      feathersClient.service('mpsv-report').patch(req.id, req.body),
    {
      onMutate: () => {
        setIsMutating(true);
      },
      onError: () => {
        ErrorAlert('Nepodařilo se upravit status reportu.');
        setIsMutating(false);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries('mpsv');
        SuccessAlert('Status reportu upraven.');
        setIsMutating(false);
      },
    },
  );

  const onSetStatus = async (
    mpsvReportId: string,
    status: MpsvReportStatus,
    changeNote?: string,
    mpsvReportData?: MpsvReportData,
  ) => {
    if (changeNote) {
      const error = mpsvReportData?.error ? [{
        code: 0,
        message: changeNote,
      }, ...mpsvReportData.error] : [{
        code: 0,
        message: changeNote,
      }];
      await patchMpsvReport({
        id: mpsvReportId,
        body: {
          status,
          error,
        } as MpsvReportData,
      });
    } else {
      await patchMpsvReport({
        id: mpsvReportId,
        body: {
          status,
        } as MpsvReportData,
      });
    }
  };

  if (!mpsvReport) {
    return null;
  }

  return (
    // Used to stop the row in mpsv table from expanding when any part of the modal is clicked
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <ChangeStatusModal
      icon={isMutating ? (<Spinner show className="w-3" />) : (<EditIcon className="w-3" />)}
      disabled={isMutating}
      status={mpsvReport.status}
      onSubmit={async (values) => {
        onSetStatus(
          mpsvReport.id,
          values.status.value,
          values.changeNote,
          mpsvReport,
        );
      }}
    />
  );
};

export default ChangeMpsvStatusButton;
