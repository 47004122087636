import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import Tooltip from 'rc-tooltip';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import { ErrorAlert, InfoAlert } from '../../../components/alerts';
import { PrinterIcon } from '../../../components/icons';
import { ApplicationState } from '../../../types/TymbeApi';
import { primaryColor } from '../../../utils/colors';
import Spinner from '../../../utils/spinner';

interface ExportOccupancyReportProps {
  shift: ShiftStateData,
}

const ExportOccupancyReport = ({ shift }: ExportOccupancyReportProps) => {
  const navigate = useNavigate();

  const { mutateAsync: fetchShiftDetail, isLoading } = useMutation(
    ['nameExport'],
    async (shiftId: number | string) => feathersClient.service('shift').get(shiftId, {
      query: {
        $eager: '[manShift.activeApplication.person.[personData, contact]]',
      },
    }),
  );

  const onExportClick = async (record: ShiftStateData) => {
    const shiftDetail = await fetchShiftDetail(record.id);
    if (!shiftDetail || !shiftDetail.manShift) {
      ErrorAlert('Nebylo možné získat detail směny');
      return;
    }

    const confirmedApplications = shiftDetail.manShift.map((ms) => ms.activeApplication)
      ?.filter((app) => app?.state === ApplicationState.CONFIRMED);

    if (confirmedApplications.length === 0) {
      InfoAlert('Tato objednávka neobsahuje přihlášky v Obsazeném stavu');
      return;
    }

    navigate('/name-report', {
      state: { nameReportData: confirmedApplications, nameReportShift: shiftDetail },
    });
  };

  return (
    isLoading ? (<Spinner />) : (
      <Tooltip overlay="Report obsazenosti" placement="top" trigger={['hover']}>
        <div>
          <PrinterIcon
            className="ty-table-row-controls"
            iconcolor={primaryColor}
            onClick={() => onExportClick(shift)}
          />
        </div>
      </Tooltip>
    )
  );
};

export default ExportOccupancyReport;
