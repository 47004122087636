import { MpsvReportResponseStatus, MpsvReportStatus } from '@tymbe/schema/enums';
import classNames from 'classnames';
import useCollapse from 'react-collapsed';

import TyAsyncSelect from '../../inputs/TyAsyncSelect';
import TyDateInput from '../../inputs/TyDateInput';
import { TySelect } from '../../inputs/TySelect';
import styles from '../Components/TyAdvancedSearchCore.module.css';
import { labelsValues } from '../Libs/enums';
import {
  loadCompanyOptions,
  loadBranchofficeOptions,
  loadDepartmentOptions,
  loadRegisteredTymberOptions,
} from '../Libs/loadOptions';

const mpsvReportStatusOptions = [{
  label: MpsvReportStatus.MANUAL.toUpperCase(),
  value: MpsvReportStatus.MANUAL,
}, {
  label: MpsvReportStatus.ERROR.toUpperCase(),
  value: MpsvReportStatus.ERROR,
}, {
  label: MpsvReportStatus.OK.toUpperCase(),
  value: MpsvReportStatus.OK,
}, {
  label: MpsvReportStatus.CHANGE.toUpperCase(),
  value: MpsvReportStatus.CHANGE,
}, {
  label: MpsvReportStatus.STORNO.toUpperCase(),
  value: MpsvReportStatus.STORNO,
}];

const mpsvReportResponseStatusOptions = [{
  label: MpsvReportResponseStatus.FAILURE.toUpperCase(),
  value: MpsvReportResponseStatus.FAILURE,
}, {
  label: MpsvReportResponseStatus.ERROR.toUpperCase(),
  value: MpsvReportResponseStatus.ERROR,
}, {
  label: MpsvReportResponseStatus.SUCCESS.toUpperCase(),
  value: MpsvReportResponseStatus.SUCCESS,
}];

const Basic: React.FC = () => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: true });

  return (
    <div>
      <div className={styles.triggerContainer}>
        <div {...getToggleProps()} className={styles.trigger}>
          <h2>Základní parametry</h2>
        </div>

        <div {...getToggleProps()} className={styles.trigger}>
          <div className={classNames(
            styles.triggerIcon,
            { [styles.triggerIconRotated]: isExpanded },
          )}
          />
        </div>
      </div>

      <section {...getCollapseProps()}>
        <div className={styles.row}>
          <div>
            <h3>Datum směny od - do</h3>
            <TyDateInput
              name={labelsValues.shiftDateFrom.name}
              label={labelsValues.shiftDateFrom.label}
              className={styles.tyInputs}
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.shiftDateTo.name}
              label={labelsValues.shiftDateTo.label}
              className={styles.tyInputs}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <h3>Čas směny</h3>
            <TyDateInput
              name={labelsValues.shiftTimeFrom.name}
              label={labelsValues.shiftTimeFrom.label}
              className={styles.tyInputs}
              minuteStep={5}
              picker="time"
            />
          </div>

          <div>
            <TyDateInput
              name={labelsValues.shiftTimeTo.name}
              label={labelsValues.shiftTimeTo.label}
              className={styles.tyInputs}
              minuteStep={5}
              picker="time"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <h3>Firma</h3>
            <TyAsyncSelect
              loadOptions={loadCompanyOptions}
              name={labelsValues.company.name}
              label={labelsValues.company.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
          <div>
            <h3>Provozovna</h3>
            <TyAsyncSelect
              loadOptions={loadBranchofficeOptions}
              name={labelsValues.branchoffice.name}
              label={labelsValues.branchoffice.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
          <div>
            <h3>Oddělení</h3>
            <TyAsyncSelect
              loadOptions={loadDepartmentOptions}
              name={labelsValues.department.name}
              label={labelsValues.department.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <h3>Tymber</h3>
            <TyAsyncSelect
              loadOptions={loadRegisteredTymberOptions}
              name={labelsValues.tymber.name}
              label={labelsValues.tymber.label}
              className={styles.tyInputs}
              isClearable
              isMulti
            />
          </div>
          <div>
            <h3>MPSV status</h3>
            <TySelect
              name={labelsValues.mpsvReportStatus.name}
              label={labelsValues.mpsvReportStatus.label}
              className={styles.tyInputs}
              options={mpsvReportStatusOptions}
              isClearable
              isMulti
            />
          </div>
          <div>
            <h3>MPSV response status</h3>
            <TySelect
              name={labelsValues.mpsvReportResponseStatus.name}
              label={labelsValues.mpsvReportResponseStatus.label}
              className={styles.tyInputs}
              options={mpsvReportResponseStatusOptions}
              isClearable
              isMulti
            />
          </div>
        </div>
      </section>
    </div>
  );
};

const Body: React.FC = () => (
  <div className={styles.container}>
    <Basic />
  </div>
);

export default Body;
