import { useCallback } from 'react';

import useSearchString from './useSearchParams';

const useSingleSearchString = (param: string, initValue?: string) => {
  const [searchString, setSearchString] = useSearchString();

  const setSingleSearchString = useCallback(
    (value: string) => {
      setSearchString((prev) => {
        if (value === initValue) {
          prev.delete(param);
        } else {
          prev.set(param, value);
        }
        return prev;
      });
    },
    [initValue, param, setSearchString],
  );

  return [searchString.get(param) ?? initValue, setSingleSearchString] as const;
};

export default useSingleSearchString;
