import { Form } from 'informed';
import { useCallback } from 'react';

import defaultPageSizeOptions from './table.utils';
import useSingleSearchString from '../../hooks/UrlParamsHandler/useSingleSearchString';
import { SecondaryButton } from '../buttons';
import { TySelect } from '../inputs/TySelect';

export type TablePaginationProps = {
  rowsCount?: number;
  onChangePage: (pageStart: number, pageEnd: number, pageSize: number) => void;
  pageSizeOptions?: { value: number, label: string }[];
  page?: number;
  pageSize?: number;
};

export const TablePagination = ({
  rowsCount = 0,
  onChangePage,
  pageSizeOptions = defaultPageSizeOptions,
  page = 1,
  pageSize = pageSizeOptions[0].value,
}: TablePaginationProps) => {
  const setPaging = (newPage: number, newPageSize = pageSize) => {
    onChangePage((newPage - 1) * newPageSize, (newPage) * newPageSize, newPageSize);
  };

  const setPrevPage = (currentPage: number) => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setPaging(newPage);
    }
  };

  const setNextPage = (currentPage: number, totalPages: number) => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setPaging(newPage);
    }
  };

  const changePagination = (option: { value: { value: number } }, prevPageSize: number, currentPage: number) => {
    const newPageSize = option.value.value;

    // Also update the page to keep the records on the page
    const pageStart = (currentPage - 1) * prevPageSize;
    const newPage = Math.floor(pageStart / newPageSize) + 1;
    setPaging(newPage, newPageSize);
  };

  // first button - always visible
  const renderPrevPageButton = (currentPage: number) => (
    <SecondaryButton
      className="mr-1"
      disabled={currentPage === 1}
      onClick={() => setPrevPage(currentPage)}
    >
      {'<'}
    </SecondaryButton>
  );

  // first button - always visible
  const renderFirstPageButton = (currentPage: number) => (
    <SecondaryButton
      className={currentPage === 1
        ? 'ty-button-secondary mr-1 !text-[#b3ca1f]'
        : 'ty-button-secondary mr-1'}
      onClick={() => setPaging(1)}
    >
      1
    </SecondaryButton>
  );

  // more previous pages button
  const renderPreviousPagesButton = (currentPage: number) => (
    <SecondaryButton
      className="mr-1"
      onClick={() => setPaging(Math.max(currentPage - 5, 1))}
    >
      ...
    </SecondaryButton>
  );

  // numbered buttons
  const renderNumberedPageButtons = (currentPage: number, totalPages: number) => {
    const pageNumberButtons = [];
    let buttonsStart = currentPage - 2;
    let buttonsEnd = currentPage + 2;
    if (currentPage < 5) {
      buttonsStart = 2;
      buttonsEnd = Math.min(5, totalPages - 1);
    } else if (currentPage > totalPages - 3) {
      buttonsStart = Math.max(2, totalPages - 5);
      buttonsEnd = totalPages - 1;
    }
    for (let i = buttonsStart; i <= buttonsEnd; i += 1) {
      pageNumberButtons.push(
        <SecondaryButton
          key={i}
          className={currentPage === i
            ? 'ty-button-secondary mr-1 !text-[#b3ca1f]'
            : 'ty-button-secondary mr-1'}
          onClick={() => setPaging(i)}
        >
          {i}
        </SecondaryButton>,
      );
    }
    return pageNumberButtons;
  };

  // more next pages button
  const renderMorePagesButton = (currentPage: number, totalPages: number) => (
    <SecondaryButton
      className="mr-1"
      onClick={() => setPaging(Math.min(currentPage + 5, totalPages))}
    >
      ...
    </SecondaryButton>
  );

  // last button - always visible
  const renderLastPageButton = (currentPage: number, totalPages: number) => (
    <SecondaryButton
      className={currentPage === totalPages
        ? 'ty-button-secondary mr-1 !text-[#b3ca1f]'
        : 'ty-button-secondary mr-1'}
      onClick={() => setPaging(totalPages)}
    >
      {totalPages}
    </SecondaryButton>
  );

  // more next pages button
  const renderNextPageButton = (currentPage: number, rows: number, size: number, pageEnd: number) => (
    <SecondaryButton
      className="mr-1"
      disabled={pageEnd === rows}
      onClick={() => setNextPage(currentPage, rows / size)}
    >
      {'>'}
    </SecondaryButton>
  );

  const pageStart = (page - 1) * pageSize;
  const pageEnd = pageStart + pageSize;
  const effectivePageEnd = pageEnd > rowsCount ? rowsCount : pageEnd;
  const pagesCount = Math.ceil(rowsCount / pageSize);
  const initialPageSize = pageSizeOptions.find((o) => o.value === pageSize) ?? pageSizeOptions[0];

  return (
    <div className="mt-4 flex justify-end w-full">
      <span className="my-auto mr-4 ml-0">
        Zobrazuji {rowsCount !== 0 ? pageStart + 1 : 0} až {effectivePageEnd} z celkem {rowsCount} záznamů
      </span>
      <span className="flex my-auto mr-4 ml-0">
        {renderPrevPageButton(page)}
        {renderFirstPageButton(page)}
        {pagesCount > 7 && page > 4 && renderPreviousPagesButton(page)}
        {renderNumberedPageButtons(page, pagesCount)}
        {pagesCount > 7 && page < pagesCount - 3 && renderMorePagesButton(page, pagesCount)}
        {pagesCount > 1 && renderLastPageButton(page, pagesCount)}
        {renderNextPageButton(page, rowsCount, pageSize, effectivePageEnd)}
      </span>
      <Form>
        <TySelect
          initialValue={initialPageSize}
          name="monthly_dpp_page_size"
          options={pageSizeOptions}
          onChange={(newValue) => changePagination(newValue, pageSize, page)}
          value={pageSizeOptions.find((o) => o.value === Number(pageSize))}
        />
      </Form>
    </div>
  );
};

export const useTablePagination = (searchStringPrefix = 'page', initPageStart = 0, initPageSize = defaultPageSizeOptions[0].value) => {
  const [pageSize = initPageSize, setPageSize] = useSingleSearchString(`${searchStringPrefix}Size`, String(initPageSize));
  const [pageStart = initPageStart, setPageStart] = useSingleSearchString(`${searchStringPrefix}Start`, String(initPageStart));
  const page = Math.floor(Number(pageStart) / Number(pageSize)) + 1;

  const onChangePage = useCallback(
    (start: number, pageEnd: number, size: number) => {
      setPageStart(String(start));
      setPageSize(String(size));
    },
    [setPageSize, setPageStart],
  );

  const setPageParams = useCallback(
    () => ({
      page: Number(page),
      pageSize: Number(pageSize),
      onChangePage,
    }),
    [onChangePage, page, pageSize],
  );

  const setPageSizeNumber = useCallback(
    (size: number) => {
      setPageSize(String(size));
    },
    [setPageSize],
  );

  const setPageStartNumber = useCallback(
    (start: number) => {
      setPageStart(String(start));
    },
    [setPageStart],
  );

  return {
    pageSize,
    pageStart,
    page,
    onChangePage,
    setPageParams,
    setPageSize: setPageSizeNumber,
    setPageStart: setPageStartNumber,
  };
};
