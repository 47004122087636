import { Service } from '@feathersjs/feathers';
import { jobClassification as getJobClassification } from '@tymbe/job-classifications/job-clasification';
import { CompanyData } from '@tymbe/schema/company.interface';
import { ContractDocumentTypes, DocumentType } from '@tymbe/schema/enums';
import { PaySupplementRules } from '@tymbe/schema/pay-supplement.interface';
import { Form, FormState } from 'informed';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import feathersClient from '../../../../../apiClient';
import { useUser } from '../../../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../../../components/alerts';
import { SubmitButton } from '../../../../../components/buttons';
import { getValuesForPatch } from '../../../../../components/forms/form-util';
import Card from '../../../../../components/Layout/Card';
import { PageTitle } from '../../../../../components/texts';
import Wrapper from '../../../../../components/wrapper';
import Container from '../../../../../containers';
import {
  DocumentTypeData,
  DocumentTypeEnum,
  isDocumentTypeData,
  isPerkData,
  ShiftTemplateData,
} from '../../../../../types/TymbeApi';
import { Roles } from '../../../../../utils/enums';
import Spinner from '../../../../../utils/spinner';
import ShiftTemplateForm, { ShiftTemplateFormValues } from '../components/ShiftTemplateForm';
import { transformShiftLabels } from '../utils/ShiftTemplate.utils';

type RouteParams = {
  shiftTemplateId: string;
  companyId: string;
};

const shiftTemplateService: Service<ShiftTemplateData> = feathersClient.service('shift-template');

const ShiftTemplate = () => {
  const history = useNavigate();
  const { shiftTemplateId, companyId } = useParams<RouteParams>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const user = useUser();

  const { data, isLoading } = useQuery(['shift-template', shiftTemplateId], async () =>
    feathersClient.service('shift-template').get(Number(shiftTemplateId), {
      query: {
        $eager: '[perk, documentType, branchoffice.parent, position, utility]',
      },
    }), { enabled: !!shiftTemplateId });

  const { data: companyData, isLoading: isCompanyLoading } = useQuery(
    ['company', companyId],
    async (): Promise<CompanyData> =>
      feathersClient.service('company').get(Number(companyId), {
        query: {
          $eager: '[defaultSettings.[position, defaultPerk, defaultUtility, defaultDocumentType]]',
        },
      }),
    { enabled: !!companyId },
  );

  const { mutateAsync: getTymbeContractDocumentType } = useMutation(['getTymbeContract'], async () =>
    feathersClient.service('document-type').find({
      query: {
        type: DocumentTypeEnum.TYMBE_CONTRACT,
        $limit: 1,
      },
    }));

  const isTymbe = useUser().hasRoles([
    Roles.SUPER_ADMIN,
    Roles.ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);

  const onSubmit = useCallback(
    async (formState: FormState<ShiftTemplateFormValues>): Promise<void> => {
      setDisabled(true);
      const modifiedAndNulled = getValuesForPatch(formState);
      const { values, modified } = formState;
      const valuesForPatch = shiftTemplateId ? { ...modifiedAndNulled } : { ...values, ...modifiedAndNulled };

      const {
        _shift_template_type,
        position,
        ...shiftTemplateData
      } = valuesForPatch;

      let { requirements, utility } = valuesForPatch;

      if (isTymbe) {
        // Normalize requirements array
        if (requirements?.length) {
          shiftTemplateData.documentType = requirements?.filter(isDocumentTypeData);
          shiftTemplateData.perk = requirements?.filter(isPerkData);
        }
      } else {
        shiftTemplateData.documentType = requirements?.filter(isDocumentTypeData) || shiftTemplateData.documentType;
        shiftTemplateData.perk = [
          ...(requirements?.filter(isPerkData) || []),
          ...(data?.perk?.filter((perk) => !perk.is_visible) || [])];
      }

      if (requirements?.length === 0) {
        requirements = null;
      }
      if (utility?.length === 0) {
        utility = null;
      }

      let paySupplement: PaySupplementRules | undefined | null;
      if ('pay_supplement' in modifiedAndNulled) {
        paySupplement = values.pay_supplement;
      }
      if ('$paySupplement' in modifiedAndNulled && modifiedAndNulled.$paySupplement === false) {
        paySupplement = null;
      }

      // This validation must happen on every create or whenever requirements are filled
      // or whenever requirements are modified during patch
      if (!shiftTemplateId || requirements?.length || (shiftTemplateId && requirements === null)) {
        // Requirements must have CONTRACT DOCUMENT documentType
        if (!shiftTemplateData.documentType?.some((doc: DocumentTypeData) =>
          ContractDocumentTypes.includes(doc.type as unknown as DocumentType))) { // FIXME TD-1613
          ErrorAlert('V podmínkách musí být vybrána pracovní smlouva');
          setDisabled(false);
          throw new Error('V podmínkách musí být vybrána pracovní smlouva');
        }

        // Requirements must have ID card
        if (!shiftTemplateData.documentType?.some((doc: DocumentTypeData) =>
          doc.type === DocumentType.ID_CARD)) {
          ErrorAlert('V podmínkách musí být vybrán doklad totožnosti');
          setDisabled(false);
          throw new Error('V podmínkách musí být vybrán doklad totožnosti');
        }

        if (!shiftTemplateData.documentType?.some((doc: DocumentTypeData) =>
          doc.type === DocumentType.TYMBE_CONTRACT)) {
          const { total, data: tymbeContract } = await getTymbeContractDocumentType();
          if (total === 0) {
            // Tymbe Contract was not found on the server (which should never happen)
            ErrorAlert('Ke vzoru nebylo možné přiřadit Tymbe smlouvu.');
            setDisabled(false);
            throw new Error('Ke vzoru nebylo možné přiřadit Tymbe smlouvu.');
          }

          shiftTemplateData.documentType?.push({ ...tymbeContract?.[0] });
        }
      }

      if (_shift_template_type?.value && _shift_template_type.value !== 'department') {
        shiftTemplateData.branchoffice = [];
      }

      if (position?.id) {
        shiftTemplateData.position_id = position?.id;
      }
      shiftTemplateData.branchoffice = shiftTemplateData.branchoffice?.map(
        ({ id, parent, ...rest }) => ({ id: Number(id), ...rest }),
      );

      delete shiftTemplateData.pausePublish;
      delete shiftTemplateData.requirements;
      delete shiftTemplateData.shiftLabels;

      // PaySupplementItem data
      delete shiftTemplateData.$paySupplement;
      delete shiftTemplateData.$Holiday;
      delete shiftTemplateData.$Weekend;
      delete shiftTemplateData.$WeekendSeparately;
      delete shiftTemplateData.$WeekendTogether;
      delete shiftTemplateData.$Saturday;
      delete shiftTemplateData.$Sunday;
      delete shiftTemplateData.$Night;
      delete shiftTemplateData.job_classification_isco;
      delete shiftTemplateData.job_classification_nace;

      shiftTemplateData.instruction = valuesForPatch.instruction;
      shiftTemplateData.description = valuesForPatch.description;
      shiftTemplateData.instruction_newcomers = valuesForPatch.instruction_newcomers;

      if (valuesForPatch.job_classification_isco || valuesForPatch.job_classification_nace) {
        shiftTemplateData.job_classification_ids = {
          cz_isco: values.job_classification_isco!.id,
          cz_nace: values.job_classification_nace?.id,
        };
      }

      if (modified.shiftLabels) {
        shiftTemplateData.labels = modified.shiftLabels.map((label) => label.value);
      }

      if (modified.shiftLabels?.length === 0) shiftTemplateData.labels = null;

      if (modified.pay_supplement) {
        shiftTemplateData.pay_supplement = values.pay_supplement;
      }
      const shiftTemplateBody = isTymbe ? ({
        ...shiftTemplateData,
        pay_supplement: paySupplement,
        documentType: requirements === null
          ? null : shiftTemplateData.documentType?.map((doc) => ({ id: doc.id })) as DocumentTypeData[],
        perk: requirements === null ? null : shiftTemplateData.perk,
        utility,
      } as ShiftTemplateData) : ({
        ...shiftTemplateData,
        pay_supplement: paySupplement,
        documentType: undefined,
        perk: requirements === null ? null : shiftTemplateData.perk,
        utility,
      } as ShiftTemplateData);

      try {
        if (shiftTemplateId) {
          await shiftTemplateService.patch(shiftTemplateId, shiftTemplateBody);
          SuccessAlert('Pracovní pozice upravena');
        } else {
          await shiftTemplateService.create({
            ...shiftTemplateData,
            company_id: Number(companyId),
          });
          SuccessAlert('Pracovní pozice vytvořena');
        }
        history(`/company/${companyId}/shift-template`);
      } catch {
        ErrorAlert('Nepodařilo se dokončit odesílání formuláře.');
      }
      setDisabled(false);
    },
    [companyId, history, shiftTemplateId],
  );

  const mapInitialData = () => {
    if ((!shiftTemplateId || !data) && !companyData) return undefined;
    const iscoId = data?.job_classification_ids?.cz_isco || companyData?.defaultSettings?.job_classification_ids?.cz_isco;
    const naceId = data?.job_classification_ids?.cz_nace || companyData?.defaultSettings?.job_classification_ids?.cz_nace;
    const shiftLabels = data?.labels || companyData?.defaultSettings?.labels;
    const perks = data?.perk || companyData?.defaultSettings?.defaultPerk;
    const documentType = data?.documentType || companyData?.defaultSettings?.defaultDocumentType;

    return {
      ...data,
      job_classification_isco: {
        id: iscoId,
        name: getJobClassification({ id: iscoId, type: 'cz_isco' }),
      },
      job_classification_nace: naceId ? {
        id: naceId,
        name: getJobClassification({ id: naceId, type: 'cz_nace' }),
      } : undefined,
      shiftLabels: shiftLabels && transformShiftLabels(shiftLabels),
      requirements: [
        ...(user.role.some((role) => [
          Roles.SUPER_ADMIN,
          Roles.ADMIN,
          Roles.TYMBE_ADMIN,
          Roles.TYMBE_COORDINATOR,
        ].includes(role.slug)) ? perks || [] : perks?.filter((perk) => perk.is_visible) || []),
        ...(documentType?.filter(
          (doc) =>
            ![
              DocumentType.CONTRACT_DPP_TEMPLATE,
              DocumentType.CONTRACT_DPC_TEMPLATE,
              DocumentType.CONTRACT_HPP_TEMPLATE,
              DocumentType.TYMBE_CONTRACT,
            ].includes(doc.type),
        ) || []),
      ],
      utility: data?.utility || companyData?.defaultSettings?.defaultUtility,
      position: data?.position || companyData?.defaultSettings?.position,
      pay_supplement: data?.pay_supplement || companyData?.defaultSettings?.pay_supplement,
    } as ShiftTemplateFormValues;
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#ff0"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <PageTitle>{shiftTemplateId ? 'Editace pracovní pozice' : 'Nová pracovní pozice'}</PageTitle>
        <Card>
          {isLoading || isCompanyLoading ? <Spinner />
            : (
              <Form
                initialValues={mapInitialData()}
                onSubmit={onSubmit}
              >
                <ShiftTemplateForm
                  shiftTemplate={data}
                  companyId={companyId}
                  companyDefaultSettings={companyData?.defaultSettings}
                />
                <SubmitButton style={{ marginTop: '18px' }} disabled={disabled} buttontext="Uložit" />
              </Form>
            )}
        </Card>
      </Wrapper>
    </Container>
  );
};

export default ShiftTemplate;
