import Wrapper from '../../../components/wrapper';
import Container from '../../../containers';
import { TyTableTitle } from '@tymbe/ty-components/table';
import { TyNavTabs, TyNavLink } from '@tymbe/ty-components/navigation';
import { Outlet } from 'react-router-dom';
import { Form } from 'informed';
import { TySearchBox } from '@tymbe/ty-components/inputs';
import { useQuery } from 'react-query';
import feathersClient from '../../../apiClient';
import {
  AbsenceState,
  ApplicationState,
  AttendanceResolution,
} from '@tymbe/schema/enums';
import { PaginatedTotalIndicator } from '../../../components/BadgePills/PaginatedTotalIndicator';
import { processingAbsencesQueryId } from './ProcessingAbsences.tab';
import { clarificationsQueryId } from './RequestedClarifications.tab';
import { unexcusedAbsencesQueryId } from './UnexcusedAbsences.tab';
import { canceledLateQueryId } from './CanceledLate.tab';

const processingAmountId = 'admin/processingAmount';
const clarificationAmountId = 'admin/clarificationAmount';
const unexcusedAmountId = 'admin/unexcusedAmount';
const canceledLateAmountId = 'admin/canceledLateAmount';

const AdminAdministrationAbsencePage = () => {
  const pageTitle = 'Absence';

  const { data: processingAmount } = useQuery(
    [processingAbsencesQueryId, processingAmountId],
    () =>
      feathersClient.service('application-excuse').find({
        query: {
          'application_excuse.state': AbsenceState.PROCESSING,
          $limit: 0,
        },
      })
  );

  const { data: clarificationAmount } = useQuery(
    [clarificationsQueryId, clarificationAmountId],
    () =>
      feathersClient.service('application').find({
        query: {
          $limit: 0,
          $modify: { requestedClarification: true, withDeleted: true },
        },
      })
  );

  const { data: unexcusedAmount } = useQuery(
    [unexcusedAbsencesQueryId, unexcusedAmountId],
    () =>
      feathersClient.service('application').find({
        query: {
          $limit: 0,
          $leftJoinRelation: '[excuses,attendance]',
          'attendance.resolution': AttendanceResolution.UNEXCUSED_ABSENCE,
          'excuses.id[$null]': true,
          $modify: { withDeleted: true },
        },
      })
  );

  const { data: canceledLateAmount } = useQuery(
    [canceledLateQueryId, canceledLateAmountId],
    () =>
      feathersClient.service('application').find({
        query: {
          $limit: 0,
          $leftJoinRelation: 'excuses',
          $joinRelation: 'shift',
          'application.state': ApplicationState.CANCELED_LATE,
          'excuses.id[$null]': true,
          $modify: { withDeleted: true },
        },
      })
  );

  return (
    <Wrapper padding="0px" margin="0px">
      <Container
        iconcolor="#B3CA1F"
        background="#fff"
        elevate
        contentstyle={{
          paddingLeft: '190px',
          paddingTop: '24px',
          paddingRight: '24px',
          paddingBottom: '24px',
        }}
        desktopHeader
        sidebar
        sidebarExtra
      >
        <TyTableTitle className="flex justify-between">
          {pageTitle}
          <Form>
            <TySearchBox name="search" placeholder="Hledej dle jména nebo ID" />
          </Form>
        </TyTableTitle>
        <TyNavTabs>
          <TyNavLink key="processing" to={{ pathname: 'processing' }}>
            Omluvenky
            <PaginatedTotalIndicator data={processingAmount} />
          </TyNavLink>
          <TyNavLink
            key="requestedClarifications"
            to={{
              pathname: 'requestedClarifications',
              search: location.search,
            }}
          >
            Žádosti o upřesnení
            <PaginatedTotalIndicator data={clarificationAmount} />
          </TyNavLink>
          <TyNavLink
            key="unexcusedAbsences"
            to={{ pathname: 'unexcusedAbsences' }}
          >
            Neomluvené absence
            <PaginatedTotalIndicator data={unexcusedAmount} />
          </TyNavLink>
          <TyNavLink key="canceledLate" to={{ pathname: 'canceledLate' }}>
            Zrušeno po termínu
            <PaginatedTotalIndicator data={canceledLateAmount} />
          </TyNavLink>
          <TyNavLink key="resolved" to={{ pathname: 'resolved' }}>
            Vyřízené omluvenky
          </TyNavLink>
        </TyNavTabs>
        <Outlet />
      </Container>
    </Wrapper>
  );
};

export default AdminAdministrationAbsencePage;
