import { TyTableCell, TyTableRow } from '@tymbe/ty-components/table';
import classNames from 'classnames';
import { HTMLAttributes, ReactNode, useState } from 'react';

export type TableRowExpandableProps = HTMLAttributes<HTMLTableRowElement> & {
  cols: Array<ReactNode | ((expanded: boolean, cantExpand: boolean) => ReactNode)>;
  cantExpand?: boolean;
  expandColSpan?: number;
};

export const TyTableRowExpandable = ({
  children,
  className,
  cols = [],
  cantExpand = false,
  expandColSpan,
  ...rest
}: TableRowExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => !cantExpand && setIsExpanded((prev) => !prev);
  return (
    <>
      <TyTableRow className={classNames({ 'hover:brightness-90 cursor-pointer': !cantExpand }, className)} {...rest} onClick={toggleExpanded}>
        {
          cols.map((col, index) => {
            if (typeof col === 'function') {
              return <TyTableCell key={`cell${index + 1}`}>{col(isExpanded, cantExpand)}</TyTableCell>;
            }
            return (
              <TyTableCell key={`cell${index + 1}`}>{col}</TyTableCell>
            );
          })
        }
      </TyTableRow>
      { (!cantExpand && isExpanded) && <tr>{children}</tr> }
    </>
  );
};
