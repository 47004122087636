import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import feathersClient from '../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../components/alerts';
import { SecondaryButton, SubmitButton } from '../../../components/buttons';
import { PlusOutlinedIcon } from '../../../components/icons';
import TyMarkdownEditor from '../../../components/inputs/TyMarkdownEditor';
import ModalShell from '../../../components/modals/ModalShell';
import { PersonNote } from '../../../types/TymbeApi';

const NewNoteButton = () => {
  const { id } = useParams();
  const qClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);

  const { mutateAsync: createNewNote } = useMutation(
    ['create-person-note'],
    async (body: Partial<PersonNote>) => {
      await feathersClient.service('person-note').create(body);
    },
  );

  const onSubmit = async ({ values }: FormState<Partial<PersonNote>>) => {
    const noteFormData = {
      note: values.note,
      person_id: Number(id),
    } as Partial<PersonNote>;
    try {
      await createNewNote(noteFormData);
      setShowModal(false);
      SuccessAlert('Nová poznámka úspěšně vytvořena');
      qClient.invalidateQueries(['person-note']);
    } catch {
      ErrorAlert('Při vytváření poznámky došlo k chybě');
    }
  };

  return (
    <>
      <div className="pb-3 flex justify-end">
        <SecondaryButton
          className="flex flex-row justify-center align-center gap-2"
          onClick={() => setShowModal(true)}
        >
          <PlusOutlinedIcon />
          <span> Nová poznámka </span>
        </SecondaryButton>
      </div>
      <ModalShell
        showModal={showModal}
        onClose={() => setShowModal(false)}
        className="w-[600px]"
      >
        <Form
          onSubmit={onSubmit}
        >
          <h3 className="ty-h3 text-align-start">
            Přidat novou poznámku
          </h3>
          <TyMarkdownEditor
            name="note"
            placeholder="Nová pozvánka"
            required="Toto pole je povinné"
          />
          <SubmitButton className="ty-button-primary mt-5" buttontext="Uložit" />
          <SecondaryButton className="ml-3" buttontext="Zavřít" onClick={() => setShowModal(false)} />
        </Form>
      </ModalShell>
    </>
  );
};

export default NewNoteButton;
