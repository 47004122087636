import { MpsvReportStatus } from '@tymbe/schema/enums';
import { MpsvReportData } from '@tymbe/schema/mpsv-report.interface';
import { ComponentProps, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import StornoModal from './StornoModal';
import feathersClient from '../../../../apiClient';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import Button from '../../../../components/buttons/Button';
import Spinner from '../../../../components/Spinner';
import { RequestBody } from '../../../../types/ReactQuery';
import TrashIcon from '../../../calendar/components/icons/TrashIcon';

type StornoMpsvButtonOwnProps = {
  applicationId: number;
  mpsvReport: MpsvReportData;
};

type StornoMpsvButtonProps = StornoMpsvButtonOwnProps & Omit<ComponentProps<'button'>, keyof StornoMpsvButtonOwnProps>;

const StornoMpsvButton = ({ applicationId, mpsvReport, ...rest }: StornoMpsvButtonProps) => {
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [isMutating, setIsMutating] = useState(false);

  const { mutateAsync: patchMpsvReport } = useMutation(
    ['patch-mpsv-report'],
    (req: RequestBody<MpsvReportData>): Promise<MpsvReportData> =>
      feathersClient.service('mpsv-report').patch(req.id, req.body),
    {
      onMutate: () => {
        setIsMutating(true);
      },
      onError: () => {
        ErrorAlert('Nepodařilo stornovat report.');
        setIsMutating(false);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries('mpsv');
        SuccessAlert('Report stornován.');
        setIsMutating(false);
      },
    },
  );

  const onSetStatus = async (
    mpsvReportId: string,
    status: MpsvReportStatus,
    changeNote?: string,
    mpsvReportData?: MpsvReportData,
  ) => {
    if (changeNote) {
      const error = mpsvReportData?.error ? [{
        code: 0,
        message: changeNote,
      }, ...mpsvReportData.error] : [{
        code: 0,
        message: changeNote,
      }];
      await patchMpsvReport({
        id: mpsvReportId,
        body: {
          status,
          error,
        } as MpsvReportData,
      });
    } else {
      await patchMpsvReport({
        id: mpsvReportId,
        body: {
          status,
        } as MpsvReportData,
      });
    }
  };

  return (
    // Used to stop the row in mpsv table from expanding when any part of the modal is clicked
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()} className="min-w-fit">
      <Button
        type="button"
        title="Změnit status na 'storno'"
        disabled={isMutating}
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
        className="enabled:hover:bg-secondary-400 enabled:hover:text-bg bg-secondary-100 min-w-fit disabled:cursor-not-allowed disabled:opacity-70"
        {...rest}
      >
        { isMutating ? (<Spinner show className="w-3" />) : (<TrashIcon className="w-3" />) }
      </Button>
      { (openModal)
        && (
          <StornoModal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            applicationId={applicationId}
            onAccept={() => { onSetStatus(mpsvReport.id, MpsvReportStatus.STORNO); }}
          />
        ) }
    </div>
  );
};

export default StornoMpsvButton;
