import { MailData } from '@tymbe/schema/mail.interface';
import { Paginated } from '@feathersjs/feathers';
import { useState } from 'react';
import { useQuery } from 'react-query';

import feathersClient from '../../../../apiClient';
import Card from '../../../../components/Layout/Card';
import MailTable from '../components/MailTable';
import { TablePagination, useTablePagination } from '../../../../components/Table';

const QueuedEmails = () => {
  const { setPageParams, pageStart, pageSize } = useTablePagination();
  const [
    sort,
    setSort,
  ] = useState<{ [key: string]: number }>({ created_at: -1 });

  const { data, isLoading } = useQuery(
    ['admin/fetchQueuedEmails', pageStart, pageSize, sort],
    () => feathersClient.service('mail').find({
      query: {
        $eager: 'login',
        $leftJoinRelation: 'login',
        result: { $null: true },
        $skip: pageStart,
        $limit: pageSize,
        $sort: sort,
      },
    }) as Promise<Paginated<MailData>>,
  );

  const onSort = (key: string, sortOrder: number) => {
    setSort({ [key]: sortOrder });
  };

  return (
    <Card>
      {isLoading || (data && data.total > 0) ? (
        <>
          <MailTable
            data={data}
            isLoading={isLoading}
            onSort={onSort}
            activeField={Object.keys(sort)?.[0]}
          />
          <TablePagination
            {...setPageParams()}
            rowsCount={data?.total || 0}
          />
        </>
      ) : (
        <h3 className="text-center"> Žádné emaily nečekají ve frontě k odeslání 🎉 </h3>
      )}
    </Card>
  );
};

export default QueuedEmails;
