import { useCallback } from 'react';

import useSingleSearchString from '../../hooks/UrlParamsHandler/useSingleSearchString';

interface UseTableSortOptions {
  group?: string;
}
interface UseTableSortOptions {
  group?: string;
  initialSortField: string;
  initialSort: 1 | 0 | -1;
}

const useTableSort = (props: UseTableSortOptions) => {
  const { group = 'sort', initialSortField, initialSort = 0 } = props;
  const [sortString] = useSingleSearchString(group, initialSortField ? `${initialSortField}=${initialSort}` : undefined);
  const [fieldName, sort] = sortString?.split('=') ?? [];
  const addSort = useCallback(
    (field: string) => ({
      field,
      group,
      initialSort: field === initialSortField ? initialSort : 0,
    }),
    [group, initialSort, initialSortField],
  );

  return { fieldName, sort, addSort, group, sortString };
};

export default useTableSort;
