import { useQuery } from 'react-query';
import feathersClient from '../../../../../apiClient';
import { Divider } from '@tymbe/ty-components/indicators';
import { ApplicationInfo } from './ApplicationInfo';
import { ApplicationExcuses } from '../../../../../components/ApplicationExcuse/ApplicationExcuses';
import { MobileShiftCard } from '../../../../../components/MobileShiftCard/MobileShiftCard';

type ApplicationDetailProps = {
  applicationId: string;
  activeExcuseId?: string;
};

export const ApplicationDetail = ({
  applicationId,
  activeExcuseId,
}: ApplicationDetailProps) => {
  const { data: application, isLoading } = useQuery(
    ['applicationDetail', applicationId],
    async () =>
      feathersClient.service('application').get(applicationId, {
        query: {
          $eager: '[excuses, shift.company, contract.documentType]',
          $modify: { withDeleted: true },
        },
      })
  );

  if (isLoading || !application || !application.shift) {
    return null;
  }

  return (
    <div>
      <MobileShiftCard shift={application.shift!} />
      <div className="p-6">
        <ApplicationInfo application={application} />
        {application.excuses.length > 0 && <Divider />}
        <ApplicationExcuses
          excuses={application.excuses}
          activeExcuseId={activeExcuseId}
          disabledOnTap
        />
      </div>
    </div>
  );
};
