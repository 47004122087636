import { MailData } from '@tymbe/schema/mail.interface';
import { Paginated } from '@feathersjs/feathers';
import moment from 'moment/moment';

import Protect from '../../../../apiClient/Protect';
import { Table, TableCell, TableHead, TablePagination, TableRow, TableSortHeader } from '../../../../components/Table';
import { Roles } from '../../../../utils/enums';

type MailTableProps = {
  data?: Paginated<MailData>,
  isLoading?: boolean,
  activeField: string,
  onSort: (field: string, activeSortOrder: number) => void,
};

const MailTable = ({ activeField, onSort, data, isLoading }: MailTableProps) => (
  <>
    <Table>
      <TableHead>
        <TableRow>
          <TableSortHeader field="login.id" activeField={activeField} onSort={onSort}> ID Příjemce </TableSortHeader>
          <TableSortHeader field="recipient" activeField={activeField} onSort={onSort}> Příjemce </TableSortHeader>
          <TableSortHeader field="subject" activeField={activeField} onSort={onSort}> Předmět </TableSortHeader>
          <TableSortHeader field="from" activeField={activeField} onSort={onSort}> Odesílatel </TableSortHeader>
          <TableSortHeader field="reply_to" activeField={activeField} onSort={onSort}> Adresa pro odpověď </TableSortHeader>
          <TableSortHeader field="created_at" activeField={activeField} onSort={onSort}> Odeslání zažádáno </TableSortHeader>
        </TableRow>
      </TableHead>
      <tbody>
        {isLoading && !data ? (<tr><td>Načítám data...</td></tr>) : undefined}
        {data?.data.map((mail) => (
          <TableRow key={mail.id}>
            <TableCell>
              {!mail.login?.person_id ? ('- Chyba rozpoznání uživatele -') : (
                <a href={`/user/${mail.login.person_id}`}>
                  {mail.login.person_id}
                </a>
              )}
            </TableCell>
            <TableCell> {mail.recipient}</TableCell>
            <TableCell> {mail.subject} </TableCell>
            <TableCell> {mail.from} </TableCell>
            <TableCell> {mail.reply_to} </TableCell>
            <TableCell> {moment(mail.created_at).format('DD.MM.YYYY HH:mm')} </TableCell>
            <Protect
              auth={[
                Roles.SUPER_ADMIN,
                Roles.TYMBE_ADMIN,
              ]}
              redirect={false}
            >
              <TableCell>
                <a href={`${mail.id}`}>
                  Detail
                </a>
              </TableCell>
            </Protect>
          </TableRow>
        ))}
      </tbody>
    </Table>
  </>
);

export default MailTable;
