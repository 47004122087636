import { Query } from '@feathersjs/feathers';
import { ApplicationState } from '@tymbe/schema/enums';
import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import moment from 'moment';

import { OrdersTableSorter } from './OrdersTable';
import apiBuilder from '../../../components/TyAdvancedSearch/Libs/apiBuilder';
import apiTransformationShiftState from '../../../components/TyAdvancedSearch/ShiftState/apiTransformationShiftState';

export const DEFAULT_ORDERS_DATE_RANGE_START = moment().startOf('day');
export const DEFAULT_ORDERS_DATE_RANGE_END = moment().endOf('day').add(6, 'days');

export type PaginationFilterType = {
  currentPage: number,
  pageSize: number,
};

export interface OrdersPageFilter {
  search?: string | number,
  showActiveOrders?: boolean,
  dateRange?: OrdersDateRangeType,
  sorter?: OrdersTableSorter,
  pagination?: PaginationFilterType,
  advancedSearch?: object,
}

type OrdersDateRangeType = {
  from?: string,
  to?: string,
};

export const isObjectEmpty = (obj: object) => Object.keys(obj).length === 0;

export const hasShiftSomeApplicationInState = (record: ShiftStateData, applicationState: ApplicationState) =>
  record?.manShift?.some((ms) => ms.activeApplication?.state === applicationState);

const buildSearchQuery = (search?: string | number): Query | undefined => {
  if (!search) return undefined;
  if (!Number.isNaN(+search)) return { id: +search };
  return {
    $joinRelation: '[shiftTemplate, branchoffice.parent]',
    $or: [
      { 'shiftTemplate.template_name': { $ilike: `%${search}%` } },
      { 'branchoffice:parent.name': { $ilike: `%${search}%` } },
    ],
  };
};

const buildActiveOrdersQuery = (active: boolean) =>
  ({ 'shift_state.deleted_at': { $null: active } });

const buildDateRangeOrdersQuery = (dateRange?: OrdersDateRangeType) => {
  if (!dateRange) return {};
  const { from, to } = dateRange;
  if (!from || !to) return {};
  return {
    start_time: {
      $gte: from,
      $lte: to,
    },
  };
};

const buildSortOrdersQuery = (sorter?: OrdersTableSorter) => {
  if (!sorter) return {};
  const { field, order } = sorter;

  if (!field || !order) return {};
  const lastIndex = field.lastIndexOf('.');
  let path;

  if (lastIndex || lastIndex > -1) {
    path = field.substring(0, lastIndex);
  }

  let query;
  if (path) query = { $joinRelation: path };

  query = {
    ...query,
    $sort: {
      [field]: order === 'ascend' ? 1 : -1,
    },
  };

  return query;
};

const buildPaginationQuery = (pagination?: PaginationFilterType) => {
  if (!pagination) return {};
  const { currentPage, pageSize } = pagination;
  return {
    $skip: pageSize * (currentPage - 1),
    $limit: pageSize,
  };
};

const buildAdvanceSearchQuery = (advanceSearch?: object) => {
  if (!advanceSearch) return {};
  return apiBuilder(
    advanceSearch,
    apiTransformationShiftState,
  );
};

export const buildFilterQuery = (filter: OrdersPageFilter): Query => {
  let query = {};
  const {
    search,
    showActiveOrders,
    dateRange,
    sorter,
    pagination,
    advancedSearch,
  } = filter;
  query = { ...query, ...buildSearchQuery(search) };
  query = { ...query, ...buildActiveOrdersQuery(showActiveOrders || true) };
  query = { ...query, ...buildDateRangeOrdersQuery(dateRange) };
  query = { ...query, ...buildSortOrdersQuery(sorter) };
  query = { ...query, ...buildPaginationQuery(pagination) };
  query = { ...query, ...buildAdvanceSearchQuery(advancedSearch) };
  return query;
};
