import { Paginated, Query } from '@feathersjs/feathers';
import { ShiftStateData } from '@tymbe/schema/shift-state.interface';
import { Row } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import InvitationsCountColumn from './components/InvitationsCountColumn';
import OrdersCountColumn from './components/OrdersCountColumn';
import OrdersHeaderPanel from './components/OrdersHeaderPanel';
import {
  buildFilterQuery,
  DEFAULT_ORDERS_DATE_RANGE_END,
  DEFAULT_ORDERS_DATE_RANGE_START,
  isObjectEmpty,
  OrdersPageFilter,
} from './components/OrdersPage.utils';
import OrdersTable, { OrdersTableSorter } from './components/OrdersTable';
import getOrdersTableColumns from './components/OrdersTable.column';
import OrdersTableHeader, { OrdersTableRangePickerType } from './components/OrdersTablePanel';
import OrderTableExpandedCategories from './components/OrderTableExpandedCategories';
import feathersClient from '../../apiClient';
import Card from '../../components/Layout/Card';
import { PageTitle } from '../../components/texts';
import Wrapper from '../../components/wrapper';
import Container from '../../containers';
import { isPaginated } from '../../types/TymbeApi';

const isShiftFilled = (record: ShiftStateData) => record?.manShift?.some(
  (ms) => (ms.activeApplication) || (ms.application && ms.application?.length > 0) || (ms.canceledApplication && ms.canceledApplication?.length > 0),
);

const normalizeData = (data?: ShiftStateData | ShiftStateData[] | Paginated<ShiftStateData>) => {
  if (!data) return { } as Paginated<ShiftStateData>;
  if (!Array.isArray(data) && !isPaginated<ShiftStateData>(data)) return { total: 1, skip: 0, limit: 10, data: [data] };
  if (Array.isArray(data) && !isPaginated<ShiftStateData>(data)) {
    return {
      total: data.length,
      skip: 0,
      limit: data.length,
      data,
    };
  }
  return data;
};

const DEFAULT_FILTER = {
  dateRange: {
    from: DEFAULT_ORDERS_DATE_RANGE_START.toISOString(),
    to: DEFAULT_ORDERS_DATE_RANGE_END.toISOString(),
  },
  sorter: {
    field: 'start_time',
    order: 'ascend',
  },
} as OrdersPageFilter;

const ShiftPage = () => {
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [filter, setFilter] = useState<OrdersPageFilter>(DEFAULT_FILTER);

  const navigate = useNavigate();

  const {
    mutateAsync: refetchTable,
    isLoading,
    data,
  } = useMutation(['shift-state'], async (query?: Query) =>
    feathersClient.service('shift-state').find({
      query: {
        $leftJoinRelation: '[manShift.[application(withDeleted),canceledApplication(withDeleted), activeApplication]]',
        $eager:
          '[shiftTemplate, branchoffice.parent, manShift(withDeleted).[application(withDeleted).[person.[personPerks, personData]], canceledApplication(withDeleted).person, activeApplication(withDeleted).[person.[personPerks, personData]]], company]',
        ...query,
      },
    }));

  const renderOrdersCountColumn = useCallback((_: unknown, shift: ShiftStateData) => {
    const showControls = moment(shift.start_time).isAfter(moment())
      && !shift.company?.is_readonly
      && shift.shift_template_id !== null;
    return (
      <OrdersCountColumn
        showControls={showControls}
        data={shift}
        onClick={() => refetchTable(buildFilterQuery(filter))}
      />
    );
  }, [filter, refetchTable]);

  const renderInvitation = (_:unknown, shift: ShiftStateData) => {
    const showControls = moment(shift.start_time).isAfter(moment()) && shift.shift_template_id !== null;
    return (
      <InvitationsCountColumn
        showControls={showControls}
        shift={shift}
        onFinished={() => refetchTable(buildFilterQuery(filter))}
      />
    );
  };

  const toggleRowSelection = useCallback(
    (record: ShiftStateData[] | ShiftStateData) => {
      const records = !Array.isArray(record) ? [record] : record;

      let newSelectedRows = selectedRowIds;
      records.forEach((rec: ShiftStateData) => {
        const i = selectedRowIds.indexOf(rec.id);

        // turning off
        if (i >= 0) {
          selectedRowIds.splice(i, 1);
          setSelectedRowIds([...selectedRowIds]);
          // turning on
        } else {
          newSelectedRows = [...newSelectedRows, rec.id];
        }
      });
      setSelectedRowIds([...newSelectedRows]);
    },
    [selectedRowIds],
  );

  const renderSelectedNameExport = () => {
    if (selectedRowIds.length === 0) return;
    navigate('/selected-shifts-report', {
      state: { ids: selectedRowIds },
    });
  };

  const onSetDateRange = ({ to, from }: OrdersTableRangePickerType) => {
    const toIso = to.endOf('day').toISOString();
    const fromIso = from.startOf('day').toISOString();

    // Do not request API, if input has not changed at all
    if (toIso === filter?.dateRange?.to && fromIso === filter?.dateRange?.from) return;
    setFilter({ ...filter, dateRange: { from: fromIso, to: toIso } });
    refetchTable(buildFilterQuery({ ...filter, dateRange: { from: fromIso, to: toIso } }));
  };

  const onSearch = (input: string | number) => {
    setFilter({ ...filter, search: input });
    refetchTable(buildFilterQuery({ ...filter, search: input }));
  };

  const toggleInactiveOrders = (active: boolean) => {
    setFilter({ ...filter, showActiveOrders: active });
    refetchTable(buildFilterQuery({ ...filter, showActiveOrders: active }));
  };

  const onAdvancedSearch = (advancedSearch?: object) => {
    setFilter({ ...filter, advancedSearch });
    refetchTable(buildFilterQuery({ ...filter, advancedSearch }));
  };

  const onPaginationChange = (page: number, newPageSize: number) => {
    setFilter({
      ...filter,
      pagination: {
        currentPage: page,
        pageSize: newPageSize,
      },
    });
  };

  const onTableChange = (_pagination: unknown, _filters: unknown, sorter: OrdersTableSorter) => {
    // Antd triggers this function no multiple changes, this statement forces the changes to happen only on sort changes
    if ((filter.sorter?.field === sorter.field
      && filter.sorter?.order === sorter.order)
      || isObjectEmpty(sorter)) return;

    // Handle sort changes
    setFilter({ ...filter, sorter });
    refetchTable(buildFilterQuery({ ...filter, sorter }));
  };

  const expandable = {
    // FIXME: We need to get rid of the Antd table to fix this...
    // eslint-disable-next-line react/no-unstable-nested-components
    expandedRowRender: (record: ShiftStateData) => (
      <Row key={`expanded${record.id}`}>
        <OrderTableExpandedCategories
          shift={record}
          onChange={() => refetchTable(buildFilterQuery(filter))}
        />
      </Row>
    ),
    rowExpandable: isShiftFilled,
  };

  // TODO: Remove the useEffect and use UseQuery instead for initial api call
  useEffect(() => {
    refetchTable(buildFilterQuery(filter));
  }, [refetchTable, filter]);

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <div className="flex justify-between items-center">
          <PageTitle>Objednávky</PageTitle>
          <OrdersHeaderPanel
            data={normalizeData(data)?.data || []}
            isLoading={isLoading}
            selectedRowIds={selectedRowIds}
            renderSelectedNameExport={renderSelectedNameExport}
          />
        </div>
        <Card>
          <OrdersTableHeader
            onSetDateFilter={onSetDateRange}
            onSearch={onSearch}
            onShowInactiveShift={toggleInactiveOrders}
            onAdvancedSearch={onAdvancedSearch}
            isLoading={isLoading}
          />
        </Card>
        <OrdersTable
          selectedRowIds={selectedRowIds}
          toggleRowSelection={toggleRowSelection}
          isLoading={isLoading}
          data={normalizeData(data)}
          columns={getOrdersTableColumns({
            renderOrdered: renderOrdersCountColumn,
            renderInvitation,
          })}
          currentPage={filter.pagination?.currentPage || 1}
          onPaginationChange={onPaginationChange}
          onTableChange={onTableChange}
          expandable={expandable}
        />
      </Wrapper>
    </Container>
  );
};

export default ShiftPage;
